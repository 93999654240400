const ITEMS_PER_PAGE = process.env.REACT_APP_API_NB_ITEMS_PER_PAGE;

const initialState = {
  items: [],
  current: {},
  usersByCompany: [],
};

export function brokers(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "brokers/brokersLoaded":
      const nbPages = Math.ceil(
        action.payload["hydra:totalItems"] / ITEMS_PER_PAGE
      );

      let page = null;

      if (action.payload["hydra:view"]) {
        const hydraId = action.payload["hydra:view"]["@id"];
        const parametersStr = hydraId.split("?")[1];

        const parameters = parametersStr.split("&");

        for (let paramStr of parameters) {
          const param = paramStr.split("=");

          if (param[0] === "page") {
            page = parseInt(param[1]);
          }
        }
      }

      return {
        ...state,
        items: action.payload["hydra:member"],
        nbPages: nbPages,
        page: page,
      };
    case "brokers/itemLoaded":
      return {
        ...state,
        current: payload,
      };
    case "usersByCompany/itemLoaded":
      return {
        ...state,
        usersByCompany: payload,
      };
    case "brokers/fetchError":
    default:
      return state;
  }
}
