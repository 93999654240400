import React, { useCallback, useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import classes from "./assets/css/styles.module.css";
import { tokensManagementLogsServices } from "./services/tokensManagementLogs.service";
import * as moment from "moment";
import { Chip } from "@mui/material";
import { Done } from "@mui/icons-material";
export default function TokensManagementLogsModal({ show, toggle, companyId }) {
  const { t } = useTranslation();
  const [logs, setLogs] = useState([]);

  const fetchLogs = useCallback(async () => {
    const logs = await tokensManagementLogsServices.getLogsByCompany(companyId);
    setLogs(logs);
  }, [companyId]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  return (
    <Modal show={show} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title className={classes?.modalTitle}>
          {t("Tokens management's history")}
          <br />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr
              style={{
                textAlign: "center",
                backgroundColor: "#b3b2b2",
                color: "#FFFFFF",
              }}
            >
              <th>{t("Date")}</th>
              <th>{t("Author")}</th>
              <th>{t("Total")}</th>
            </tr>
          </thead>
          <tbody>
            {logs?.map((log, index) => {
              return (
                <tr role="button">
                  <td>{moment(log?.log_date).format("DD/MM/yyyy HH:mm:ss")}</td>
                  <td>{log?.author}</td>
                  <td>
                    {
                      <Chip
                        variant={index === 0 ? "filled" : "outlined"}
                        color={"warning"}
                        label={
                          log?.illimited ? "Unlimited" : `${log?.total} Tokens`
                        }
                        icon={index === 0 && <Done fontSize="12px" />}
                      />
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
