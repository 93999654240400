import { Checkbox, Drawer, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./assets/css/vesselsDrawer.module.css";
import { Button, Form, Spinner } from "react-bootstrap";
import { vesselServices } from "../../Vessels/services/vessels.services";

export default function VesselsDrawer({
  show,
  toggle,
  vessels,
  formik,
  template,
  setVessels,
  folderType,
  isDay,
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [initialVessels] = useState(vessels);
  const onSelectRow = (id) => {
    setSelected(id);
  };
  const onSearch = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      setVessels(initialVessels);
    } else {
      const v = initialVessels?.filter((vessel) =>
        vessel?.sheetname.toLowerCase().includes(searchText.toLowerCase())
      );
      setVessels(v);
    }
  };
  const setFormikBase = (details, folderType) => {
    if (isDay) {
      formik.setFieldValue(
        "45006844-88a3-437e-9542-5aee3fffb073",
        details?.vessel?.vesselName
      );

      formik.setFieldValue(
        "c87817af-9650-426e-a7fb-f6cdb132629d",
        details?.vessel?.vesselIMO
      );

      formik.setFieldValue(
        "31cf6cb1-38fa-4957-85ff-0b06739411fa",
        details?.vessel?.vesselRegistryPort
      );

      formik.setFieldValue(
        "25eeb678-6bda-4e1f-ae43-6fa9585ea833",
        details?.vessel?.vesselFlag
      );

      formik.setFieldValue(
        "a187bec6-35a0-4360-8896-807e87362ccf",
        details?.vessel?.vesselLOA
      );

      formik.setFieldValue(
        "8b1d6361-2ffd-4977-aaff-683278a38700",
        details?.vessel?.vesselType
      );

      formik.setFieldValue(
        "6b2e1513-9b92-4eb1-873e-f0e78fe0a438",
        details?.vessel?.vesselSpecialConditions
      );
    } else {
      switch (folderType) {
        case "transport":
          formik.setFieldValue(
            "d23a3965-1af3-4419-a805-c44a064a1edb",
            details?.vessel?.vesselName
          );

          formik.setFieldValue(
            "88ccaf38-2ff0-4c5a-878b-e1a11fb11ef2",
            details?.vessel?.vesselIMO
          );

          formik.setFieldValue(
            "b540b9af-303d-4fb5-8d4b-c202b50b03b7",
            details?.vessel?.vesselRegistryPort
          );

          formik.setFieldValue(
            "cee28b8d-4efb-4c28-86e0-3a72d9da9720",
            details?.vessel?.vesselFlag
          );

          formik.setFieldValue(
            "d5b950b8-2324-423c-a99b-2e0a30dad17a",
            details?.vessel?.vesselLOA
          );

          formik.setFieldValue(
            "4cf0b121-f24c-497d-a42a-9c4d8949f6c5",
            details?.vessel?.vesselType
          );

          formik.setFieldValue(
            "106bb317-b8e3-4ace-9923-95ee807af9dd",
            details?.vessel?.vesselSpecialConditions
          );
          break;

        case "charter":
          formik.setFieldValue(
            "14effde2-5e1e-41c7-b934-ad43316ef30c",
            details?.vessel?.vesselName
          );

          formik.setFieldValue(
            "c9f359fb-7f92-452b-9c81-8cf32ea8e111",
            details?.vessel?.vesselIMO
          );

          formik.setFieldValue(
            "cf2bafbf-a20e-4f03-a121-25aa30a48952",
            details?.vessel?.vesselRegistryPort
          );

          formik.setFieldValue(
            "cdf304cd-1e10-4ca4-9037-7f6112863d91",
            details?.vessel?.vesselFlag
          );

          formik.setFieldValue(
            "a3b26a2d-e010-45d2-8b57-50f00bae109d",
            details?.vessel?.vesselLOA
          );

          formik.setFieldValue(
            "d7b29ac8-7b37-463d-aaab-0128de8cb571",
            details?.vessel?.vesselType
          );

          formik.setFieldValue(
            "64bae275-6bf1-4125-9008-0afef2600162",
            details?.vessel?.vesselSpecialConditions
          );
          break;

        default:
          break;
      }
    }
  };
  const onSubmitBase = async () => {
    try {
      setIsLoading(true);
      const details = await vesselServices.getVesselById(selected);
      setFormikBase(details, folderType);
      setIsLoading(false);
      toggle();
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const setFormikAgencyAgreement = (details) => {
    formik.setFieldValue(
      "fffd1237-0d7e-4a3b-a9a7-8cf1d4f61d58",
      details?.vessel?.vesselName
    );

    formik.setFieldValue(
      "b95da880-62f4-42dc-aa25-ae0d2acb2acb",
      details?.vessel?.vesselIMO
    );

    formik.setFieldValue(
      "03172b71-730f-49a6-a44b-6775a4fce3d5",
      details?.vessel?.vesselFlag
    );

    formik.setFieldValue(
      "4ba89a8e-bacd-4c39-8759-3d092620087c",
      details?.vessel?.vesselLOA
    );

    formik.setFieldValue(
      "1a8914c4-1648-4a8e-b310-83cdb3a52cc9",
      details?.vessel?.vesselType
    );
  };
  const onSubmitAgencyAgreement = async () => {
    try {
      setIsLoading(true);
      const details = await vesselServices.getVesselById(selected);
      setFormikAgencyAgreement(details);
      setIsLoading(false);
      toggle();
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <Drawer open={show} onClose={toggle} anchor="right">
      <div className={classes.drawer}>
        <span className={classes.title}>{t("Select one vessel sheet")}</span>
        <Form.Control
          type="text"
          placeholder={t("Search ...")}
          value={searchText}
          onChange={onSearch}
        />
        {vessels?.map((vessel) => {
          return (
            <TableRow className={classes["MuiTableRow-root"]}>
              <TableCell
                padding="checkbox"
                className={classes["MuiTableCell-root"]}
              >
                <Checkbox
                  color="primary"
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                  onChange={() => onSelectRow(vessel?.id)}
                  checked={selected === vessel?.id}
                />
              </TableCell>
              <TableCell className={classes["MuiTableCell-root"]}>
                {vessel?.sheetname}
              </TableCell>
            </TableRow>
          );
        })}
      </div>
      <Button
        className={classes["btn-drawer"]}
        onClick={
          template === "agencyAgreement"
            ? onSubmitAgencyAgreement
            : onSubmitBase
        }
        disabled={isLoading}
      >
        {isLoading && <Spinner animation="border" size="sm" />}
        <span>{t("Save")}</span>
      </Button>
    </Drawer>
  );
}
