import React, { useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vesselServices } from "../services/vessels.services";
import { useDispatch } from "react-redux";
import { alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";
import classes from "../assets/css/vessels.module.css";
import { history } from "../../_helpers";

export default function CopyVessel({
  vesselId,
  toggle,
  show,
  sheetName,
  userId,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [newSheetName, setNewSheetName] = useState("");

  useMemo(() => {
    setNewSheetName(`${sheetName} (copie)`);
  }, [sheetName]);
  const handleChange = (e) => {
    setNewSheetName(e.target.value);
  };
  const handleCopy = async () => {
    try {
      setIsLoading(true);
      const response = await vesselServices?.copy(
        vesselId,
        userId,
        newSheetName
      );
      history.push(`/vessels/edit/${response?.id}`);
      dispatch(
        alertActions.setAlert(t(response?.message), alertConstants.SUCCESS)
      );
    } catch (error) {
      dispatch(alertActions.setAlert(t(error), alertConstants.ERROR));
    }
  };

  return (
    <>
      <Modal show={show} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title className={classes.modalTitle}>
            {t("Duplicate vessel")}
            <br />
            {sheetName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            value={newSheetName}
            onChange={handleChange}
            placeholder="Enter a sheet name"
            required
          />
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <Button onClick={handleCopy} disabled={isLoading}>
            {t("Duplicate")}
          </Button>
          <Button onClick={toggle}>{t("Cancel")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
