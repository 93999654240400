import styles from "./ContractStyles";
import timesNewRoman from "../fonts/times-new-roman.ttf";
import timesNewRomanBold from "../fonts/times-new-roman-grassetto.ttf";
import { Font } from "@react-pdf/renderer";

Font.register({
  family: "Times New Roman",
  src: timesNewRoman,
});

Font.register({
  family: "Times New Roman Bold",
  src: timesNewRomanBold,
});

const jsonView = {
  type: "page",
  items: [
    {
      type: "view",
      styles: {
        paddingLeft: 25,
        paddingTop: 30,
      },
      items: [
        {
          type: "text",
          styles: {
            fontSize: 7,
          },
          content: "ECPY European Charter Agreement",
        },

        {
          type: "text",
          styles: {
            paddingTop: 7,
            fontSize: 12,

            textAlign: "right",
          },
          content: "N° __agreementUniqueNumber__",
        },

        {
          type: "text",
          styles: {
            fontSize: 15,
            marginTop: 15,
            textAlign: "center",
            fontFamily: "Times New Roman Bold",
          },
          content: "ECPY European Charter Agreement",
        },
      ],
    },

    {
      type: "view",
      styles: {
        paddingTop: 10,
      },
      items: [
        {
          type: "text",
          styles: {
            fontSize: 11,
            marginTop: 5,
            marginBottom: 5,
          },

          content:
            "__Otheragreementcurrency__  __CharterSignDate__ in __CharterSignPlace__",
        },

        {
          type: "text",
          styles: {
            fontSize: 11,
            marginTop: 5,
            marginBottom: 5,
          },

          content: 'BETWEEN __CharterClient__ Hereinafter the "Charterer"',
        },

        {
          type: "text",
          styles: {
            fontSize: 11,
            marginTop: 5,
            marginBottom: 5,
          },

          content: 'AND __shipOwner__ Hereinafter the "Owner"',
        },

        {
          type: "text",
          styles: {
            fontSize: 11,
            marginTop: 5,
            marginBottom: 5,
          },

          content: 'Jointly the "Parties"',
        },

        {
          type: "text",
          styles: {
            fontSize: 11,
            marginTop: 5,
            marginBottom: 5,
          },

          content:
            "In consideration of the mutual undertakings contained herein, the Parties agree to the following",
        },
      ],
    },

    {
      type: "view",
      styles: {
        padding: 0,
        marginBottom: 15,
      },
      items: [
        {
          type: "text",
          styles: {
            fontSize: 11,
            marginTop: 5,
            marginBottom: 5,
            fontFamily: "Helvetica-Bold",
          },
          content: "INFORMATION ABOUT THE VESSEL",
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
          },

          items: [
            {
              type: "view",
              styles: {
                width: "50%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Name of Vessel : __CharterVesselName__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Port of registry : __CharterRegistryPort__",
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "Type : ",
                    },

                    {
                      type: "view",
                      specialType: "choice",
                      linkedField: "ChaterYachtType",
                      withPadding: true,
                      styles: {
                        flexDirection: "row",
                      },
                      items: [],
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "50%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "IMO number : __CharterimoNumber__ ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Flag : ",
                },
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: -17,
                    marginBottom: 5,
                    marginLeft: 50,
                  },
                  content: "__CharterFlagState__",
                },
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Overall length : __CharterYachtLOADinMeters__ meters ",
                },
              ],
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "DETAILS OF THE PARTIES",
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Charterer :  ",
                },

                {
                  type: "text",

                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "__chartername1__",
                },
              ],
            },
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Address :  ",
                },

                {
                  type: "text",

                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "__ChartererAddress__",
                },
              ],
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Owner : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "__ownerName2__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Registered Office Address : __ownerAddress__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Company registration and country of incorporation : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: " __ownerCompanyId__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },

              items: [
                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "Insurance : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __ownerrInsuranceReference__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "VAT N° : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __ownerVATReference__",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "view",
          styles: {
            flexDirection: "row",
          },
          items: [
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "__enpresencede__",
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Broker : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "__brokerName__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Registered Office Address :   __brokerAddress__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Company registration and country of incorporation : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: " __brokerCompanyId__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },

              items: [
                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "VAT N° : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __brokerVATReference__",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Central agent : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "__centralAgent__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Registered Office Address : __centralAgentAddress__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Company registration and country of incorporation : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: " __centralAgentCompanyId__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },

              items: [
                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "VAT N° : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __centralAgentVATReference__",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Stakeholder : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "__stakeholder__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Registered Office Address : __stakeholderAddress__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Company registration and country of incorporation : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: " __stakeholderCompanyId__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },

              items: [
                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "VAT N° : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __stakeHolderVATreference__",
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },

              items: [
                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "auto",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "Insurance : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __stakeHolderInsuranceReference__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "auto",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "Financial guarantee : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __stakeHolderFinancialGuarantee__",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "view",

          styles: {
            position: "absolute",
            bottom: -50,
            left: 0,
            display: "flex",
            flexDirection: "row",
          },

          items: [
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "PLEASE INITIAL",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "OWNER ______________",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "CHARTERER _______________",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      type: "view",
      items: [
        {
          type: "view",
          attrs: { break: true },
          items: [
            {
              type: "view",
              styles: {
                flexDirection: "row",
                paddingTop: 30,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "__lignePage2__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 15,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "CRUISE PARTICULARS",
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content:
                    "From (date and time) : __cruiseStartDate__ __cruiseStartTime__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content:
                    "To (date and time) : __cruiseEndDate__ __cruiseEndTime__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "Port of embarkation : __embarkationPort__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "Port of disembarkation : __disembarkationPort__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "Port of delivery : __deliveryPort__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "Port of redelivery : __redeliveryPort__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "Cruising area : __cruisingArea__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Average duration of navigation (vessel underway) : __totalHoursOfNavigation__ hours (per 24 hours period)",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Maximum number of Passengers with staterooms (__paxWithCabinNb__) and day Passengers on board (__dayPaxWithoutCabinNb__)",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Maximum number of Passengers in Port  (__paxInportNb__)",
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  withPadding: true,
                  specialType: "checkbox",
                  linkedField: "staticCharter",

                  styles: {
                    flexDirection: "row",
                    ...styles.checkbox,
                  },
                  items: [],
                },
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    marginLeft: 15,
                  },
                  content: " \u00A0 Static Charter (without navigation)",
                },
              ],
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "PRICE OF CHARTER",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "Agreement currency : __currencyMain__ ",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Charter Fee : __totalPriceExclVat__ __currencyMain__  EXC VAT",
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "VAT rate : __vatRate__ %",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "VAT amount : __vatAmount__ __currencyMain__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Total price for Charter : __totalPriceIncVat__ __currencyMain__ INC VAT",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
                textAlign: "right",
              },
              content:
                "Advance provisioning allowance : __advanceProvisioning__ __currencyMain__",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
                textAlign: "right",
              },
              content:
                "Security deposit : __securityDeposit__ __currencyMain__",
            },
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
                textAlign: "right",
              },
              content:
                "Delivery/Redelivery fees : __CostofDelivery__ __currencyMain__",
            },
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
                textAlign: "right",
                fontFamily: "Helvetica-Bold",
              },
              content:
                "Total amount of payment : __totalAmountPaymentTotal__ __currencyMain__",
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "TERMS OF PAYMENT",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "First installment : __firstInstalment__ __currencyMain__ - __firstInstalmentDate__",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Second instalment : __secondInstalment__ __currencyMain__ - __secondInstalmentDate__",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Third instalment : __thirdInstalment__ __currencyMain__ - __thirdInstalmentDate__",
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "BANK TRANSFER DETAILS",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "Bank : __bankDesignation__",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "Account name : __bankAccountName__",
            },
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "Address  : ",
            },
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: -18,
                marginLeft: 50,
              },
              content: " __bankAddress__",
            },
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "IBAN : __bankIban__",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "SWIFT : __bankBicSwift__",
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "LANGUAGE",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "This Agreement is provided in English, with a non-official version in French being available. In case of a dispute regarding the interpretation of Agreement, the sole English version, included its appendices, is regarded as the legal reference of Agreement. \n In keeping with contemporary usage, in this Agreement, the question of the gender of the Charterer, Owner and Captain is addressed by the use of they, their, them as the singular, non-gendered equivalent of he / she, his / her, him / her.",
            },
          ],
        },

        {
          type: "view",

          styles: {
            position: "absolute",
            bottom: -70,
            left: 0,
            display: "flex",
            flexDirection: "row",
          },

          items: [
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "PLEASE INITIAL",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "OWNER ______________",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "CHARTERER _______________",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      type: "view",
      attrs: { break: true },
      styles: {
        height: "90%",
        paddingTop: 10,
      },
      items: [
        {
          type: "view",

          styles: {
            height: "90vh",
            overflow: "hidden",
            border: "1pt solid #000000",
            padding: 10,
            marginTop: 15,
          },
          items: [
            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
                textAlign: "center",
              },
              content: "SPECIAL CONDITIONS",
            },

            {
              type: "text",
              styles: {
                fontSize: 11,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "__specialConditions__",
            },
          ],
        },
        {
          type: "view",

          styles: {
            position: "absolute",
            bottom: -50,
            left: 0,
            display: "flex",
            flexDirection: "row",
          },

          items: [
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "PLEASE INITIAL",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "OWNER ______________",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "CHARTERER _______________",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      type: "view",
      attrs: {
        break: true,
      },
      items: [
        {
          type: "text",
          styles: {
            fontSize: 11,
            marginTop: 20,
            marginBottom: 5,
            fontFamily: "Helvetica-Bold",
            textAlign: "center",
          },
          content: "SIGNATURES",
        },

        {
          type: "text",
          styles: {
            fontSize: 11,
            marginTop: 5,
            marginBottom: 5,
          },
          content:
            "The Parties agree that the Special Conditions, the General Conditions and the Appendices form an integral part of the Contract. The provisions of the Contract shall be brought to the attention of the Charterer prior to any contractual commitment and this Contract shall constitute, prior to its signature by the Charterer, the prior information required by European regulations, their national transpositions or any equivalent regulations. Charterer is furthermore responsible for his Guests and is obliged to inform them of the terms of this Agreement, in particular of the contents of Articles 7, 10, 11 and 12 of the General Conditions.The Broker, the Central agent and the Receiver are not parties to this Agreement and their signature only attests to the fact that they are aware of its existence and contents. Their involvement is governed by other contractual documents.This Agreement, once signed, is binding on the Parties. In the case of a signature transmitted by facsimile/e-mail, or as a PDF file sent by electronic mail, such signature shall constitute a legal and binding obligation of the executing party with the same force and effect as if this Agreement were an original.",
        },
        {
          type: "view",
          styles: {
            flexDirection: "row",
            border: "2pt solid #000000",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "50%",
                border: "1pt solid #000000",
                padding: 5,
                paddingTop: "28%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Charterer : __chartername2__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Date : ",
                },
                {
                  type: "view",
                  styles: {
                    ...styles.checkboxWrapper,
                    marginTop: "10pt",
                    borderTop: "1pt solid #000000",
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        ...styles.checkbox,
                      },
                      items: [],
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 11,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content:
                        "I hereby declare that I have read, understood and agree to all the terms of this Agreement, its conditions and appendices",
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    'Represented by "name, capacity of signatory, full address", duly authorised as the Client\'s representative for the signature of this Agreement',
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "SIGNATURE : ____",
                },

                {
                  type: "view",
                  styles: {
                    height: "50pt",
                  },
                  items: [],
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "50%",
                border: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Owner :  __ownerName__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    'Represented by "name, capacity of signatory, full address", duly authorised as the Service Provider\'s representative for signature of this Agreement',
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "SIGNATURE : ____",
                },

                {
                  type: "view",
                  styles: {
                    height: "50pt",
                  },
                  items: [],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          styles: {
            marginTop: "3pt",
            border: "1pt solid #000000",
            flexDirection: "row",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "33%",
                borderRight: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Broker : __brokerName__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    paddingBottom: "50pt",
                  },
                  content:
                    'Represented by "name, capacity of signatory, full address", duly authorised as the Broker\'s representative for signature of this Agreement',
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "33%",
                borderRight: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Central agent : __centralAgent__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    paddingBottom: "50pt",
                  },
                  content:
                    'Represented by "name, capacity of signatory, full address", duly authorised as the Central agent\'s representative for signature of this Agreement',
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "33%",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Stakeholder : __stakeholder__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 11,
                    marginTop: 5,
                    marginBottom: 5,
                    paddingBottom: "50pt",
                  },
                  content:
                    'Represented by "name, capacity of signatory, full address", duly authorised as the Stakeholder\'s representative for signature of this Agreement',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
export default jsonView;
