import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Modal, Button } from "react-bootstrap";

import { box } from "tweetnacl";
import { encodeBase64 } from "tweetnacl-util";

import { brokersService } from "../../_services";

import { alertActions, fetchBroker } from "../../_actions";
import { alertConstants } from "../../_constants";

import { useTranslation } from "react-i18next";

export const GenerateKeysModal = ({
  isShown,
  handleClose,
  handleSubmit,
  resetting = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state.authentication.user);

  const [publicKey, setPublicKey] = useState(null);
  const [privateKey, setPrivateKey] = useState(null);

  const generateKeys = () => {
    sessionStorage.removeItem("privateKey");
    const brokerKeys = box.keyPair();
    const privateKey = encodeBase64(brokerKeys.secretKey);
    const publicKey = encodeBase64(brokerKeys.publicKey);
    setPublicKey(publicKey);
    setPrivateKey(privateKey);
  };

  const endFirstConnection = async () => {
    try {
      const updatedBroker = {
        id: user.id,
        publicKey: publicKey,
        resetAsked: false,
      };

      const res = await brokersService.update(updatedBroker);
      dispatch(fetchBroker(res.id));

      handleSubmit();
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }
  };

  return (
    <Modal show={isShown} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Generate your crypt keys")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!privateKey && (
          <>
            {resetting ? (
              <p>
                {t(
                  "WARNING : resetting your keys means that you won't be able to access your current contract's data anymore. This action can't be reversed !"
                )}
              </p>
            ) : (
              <p>
                {t(
                  "Your password has been updated, you are now going to generate your crypt keys. Your private key allows you to secure your contract's data, and should be safely kept."
                )}
              </p>
            )}

            <Button
              onClick={generateKeys}
              variant={resetting ? "danger" : "primary"}
            >
              {t("Generate my crypt keys")}
            </Button>
          </>
        )}

        {privateKey && (
          <>
            <p>
              {t("Your keys have been generated, find your private key below")}{" "}
              ; <strong>{t("warning")}</strong>,{" "}
              {t(
                "kept it safe because you won't be able to access your data if you loose it"
              )}{" "}
              !
            </p>

            <p>
              <strong>{t("Your private key")} : </strong> <br />
              {privateKey}
            </p>

            <Button onClick={endFirstConnection} variant="primary">
              {t("End")}
            </Button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
