import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vesselServices } from "../services/vessels.services";
import { useDispatch } from "react-redux";
import { alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";
import classes from "../assets/css/vessels.module.css";

export default function TransferVessel({
  vesselId,
  toggle,
  show,
  sheetName,
  userId,
  setVessels,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleTransfer = async () => {
    try {
      setIsLoading(true);
      const response = await vesselServices?.transfer(vesselId, userId, email);
      const vessels = await vesselServices?.getVesselsByAuthor(userId);
      setVessels(vessels);
      setIsLoading(false);
      toggle();
      dispatch(alertActions.setAlert(t(response), alertConstants.SUCCESS));
    } catch (error) {
      dispatch(alertActions.setAlert(t(error), alertConstants.ERROR));
    }
  };

  return (
    <>
      <Modal show={show} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title className={classes.modalTitle}>
            {t("Transfer vessel")}
            <br />
            {sheetName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="email"
            value={email}
            onChange={handleChange}
            placeholder="Enter user's email"
            required
          />
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <Button onClick={handleTransfer} disabled={isLoading}>
            {t("Transfer")}
          </Button>
          <Button onClick={toggle}>{t("Cancel")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
