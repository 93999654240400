import React, { useState } from "react";
import Pack from "./Pack";
import { Image, Modal } from "react-bootstrap";
import ForwardIcon from "./assets/icons/forward-icon.svg";
import BackIcon from "./assets/icons/back-icon.svg";
import { useTranslation } from "react-i18next";
export default function PackList({ packs, show, setShow }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 3;
  const { t } = useTranslation();

  const visiblePacks = packs.slice(currentIndex, currentIndex + itemsPerPage);

  const handleNext = () => {
    if (currentIndex + itemsPerPage < packs.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };

  const handlePrevious = () => {
    if (currentIndex - itemsPerPage >= 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };
  const children = (
    <div className="pack-list">
      <Image
        src={BackIcon}
        role="button"
        onClick={handlePrevious}
        style={{ visibility: currentIndex === 0 ? "hidden" : "visible" }}
      />

      {visiblePacks.map((pack, index) => (
        <Pack
          key={index}
          title={pack?.title}
          subtitle={pack?.subtitle}
          content={pack?.content}
          price={pack?.price}
        />
      ))}

      <Image
        src={ForwardIcon}
        role="button"
        onClick={handleNext}
        style={{
          visibility:
            currentIndex + itemsPerPage >= packs.length ? "hidden" : "visible",
        }}
      />
    </div>
  );
  return (
    <Modal show={show} centered onHide={setShow}>
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {t("Our packs")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3">{children}</Modal.Body>
    </Modal>
  );
}
