import { authHeader } from "../../../_helpers";
import { ApiService } from "../../../_services/api";
import axios from "axios";

const apiService = new ApiService();
apiService.setApiUrl("/tokens-manage-logs");

export const tokensManagementLogsServices = {
  getLogsByCompany,
};

async function getLogsByCompany(companyId) {
  try {
    const res = await axios.get(
      `${apiService.apiUrl}/${companyId}`,
      authHeader()
    );
    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}
