import { brokersService } from "../_services";

import { alertConstants } from "../_constants";
import { alertActions } from "./alert.actions";

export const fetchBrokers =
  (page = 1, nameSearch = "", firstnameSearch = "") =>
  async (dispatch) => {
    try {
      const brokers = await brokersService.findAll();

      dispatch({
        type: "brokers/brokersLoaded",
        payload: brokers,
      });
    } catch (err) {
      console.error(`Error : ${err}`);

      dispatch({
        type: "brokers/fetchError",
        payload: null,
      });

      dispatch(
        alertActions.setAlert(
          "Une erreur s'est produite.",
          alertConstants.ERROR
        )
      );
    }
  };

export const fetchBroker = (id) => async (dispatch) => {
  try {
    const broker = await brokersService.findBroker(id);
    dispatch({
      type: "brokers/itemLoaded",
      payload: broker,
    });
  } catch (err) {
    dispatch({
      type: "brokers/fetchError",
      payload: null,
    });

    dispatch(alertActions.setAlert(err, alertConstants.ERROR));
  }
};

export const fetchBrokersByCompany = (companyId) => async (dispatch) => {
  try {
    const brokers = await brokersService.findBrokersByCompany(companyId);
    dispatch({
      type: "usersByCompany/itemLoaded",
      payload: brokers,
    });
  } catch (err) {
    dispatch({
      type: "brokers/fetchError",
      payload: null,
    });

    dispatch(alertActions.setAlert(err, alertConstants.ERROR));
  }
};
