import styles from "./ContractStyles";

const jsonView = {
  type: "page",
  items: [
    {
      type: "view",
      styles: {
        margin: 5,
        marginLeft: 20,
        padding: 5,
      },
      items: [
        {
          type: "text",
          styles: {
            ...styles.textContent,
            fontSize: 16,
            marginTop: 0,
            textAlign: "center",
            fontFamily: "Helvetica-Bold",
          },
          content:
            "ADDENDUM TO THE TRANSPORT AND SERVICES AGREEMENT FOR YACHTS",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
            fontStyle: "italic",
          },
          content:
            "This document modifies the Transport and Services Agreement. If there is no change to the price, date/place of disembarkation, appendix I - Rider of the Agreement should be used instead.",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
          },
          content:
            "Further to the Transport Agreement signed on __addendumSignDate__ in __addendumSignPlace__",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
          },
          content: "BETWEEN __addendumClient__ the Client",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
          },
          content: "AND __addendumShipowner__ the Service Provider ",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
          },
          content:
            "Information on the Agreement : N° __addendumAgreementNumber__",
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "50%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content: "Name of the vessel : __addendumVesselName__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "50%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content:
                    "Term of the Agreement from __addendumTermFrom__ to __addendumTermTo__",
                },
              ],
            },
          ],
        },
        {
          type: 'text',
          styles: {
            ...styles.textContent,
            fontFamily: 'Helvetica-Bold',
            textDecoration: 'underline',
          },
          content: 'The following elements of the day agreement are modified',
        },
        {
          type: "text",
          styles: {
            border: "1pt solid #000000",
            padding: "5px",
            ...styles.textContent,
          },
          content: "__addendumChanges__",
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
          },
          items: [
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "The price of the Service is modified as follows : ",
                    },
                  ],
                },

                {
                  type: "view",
                  specialType: "choice",
                  linkedField: "addendumPriceChange",
                  withPadding: true,
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "50%",
              },
              items: [],
            },

            {
              type: "view",
              styles: {
                width: "50%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    textAlign: "center",
                  },
                  content:
                    "__addendumPriceChangeValue__ __addendumCurrency__ (exc VAT)",
                },
              ],
            },
          ],
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
            marginTop: 10,
          },
          content: "Total price of the Service after this addendum",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
          },
          content:
            "All inclusive price : __addendumTotalPriceExclVat__ __addendumCurrency__ EXCL VAT",
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
          },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                width: "50%",
              },
              content: "VAT rate : __addendumVATRate__ %",
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
              },
              content:
                "VAT amount : __addendumVatAmount__ __addendumCurrency__",
            },
          ],
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
          },
          content:
            "All inclusive price : __addendumTotalPriceInclVat__ __addendumCurrency__ INC VAT",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
            textAlign: "right",
          },
          content:
            "Advance provisioning allowance : __addendumAdvanceProvisioning__ __addendumCurrency__",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
            textAlign: "right",
          },
          content:
            "Security deposit : __addendumSecurityDeposit__ __addendumCurrency__",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
            marginTop: 10,
          },
          content: "TERMS OF ADDITIONAL PAYMENT",
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
          },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                width: "33%",
              },
              content: "Amount : __addendumTermAmount__ __addendumCurrency__",
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                width: "33%",
              },
              content: "Date : __addendumTermDate__",
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                width: "33%",
              },
              content: "Payment method : __addendumTermPaymentMethod__",
            },
          ],
        },

       

        {
          type: "view",
          attrs: { wrap: false },
          styles: {},
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "SIGNATURES",
            },

            {
              type: "view",
              styles: {
                border: "1pt solid #000000",
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                    borderRight: "1pt solid #000000",
                    padding: 5,
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Client : __addendumClient1__ ",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "Date : ",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        paddingBottom: 50,
                        fontSize: 8,
                      },
                      content:
                        'Represented by "name, capacity of signature, full address", duly authorised as the Client\'s reprensentative for the signature of this Agreement',
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "50%",
                    padding: 5,
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Service Provider : __addendumServiceProvider1__ ",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "Date : ",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        paddingBottom: 50,
                        fontSize: 8,
                      },
                      content:
                        'Represented by "name, capacity of signature, full address", duly authorised as the Service Provider\'s reprensentative for the signature of this Agreement',
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
                border: "1pt solid #000000",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "33%",
                    borderRight: "1pt solid #000000",
                    padding: 5,
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Broker : ",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "Date : ",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "33%",
                    borderRight: "1pt solid #000000",
                    padding: 5,
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Central agent : ",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "Date : ",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "33%",
                    padding: 5,
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Stakeholder : ",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "Date : ",
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                borderRight: "1pt solid #000000",
                borderLeft: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                    fontSize: 8,
                  },
                  content:
                    "Represented by \"name, capacity of signatory, full address\", duly authorised as the (respectively) Broker's, Central agent's and Stakeholder's representative for the signature of this Agreement",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
                border: "1pt solid #000000",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "33%",
                    padding: 5,
                    borderRight: "1pt solid #000000",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        paddingBottom: 40,
                      },
                      content: "Broker's representative",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "33%",
                    padding: 5,
                    borderRight: "1pt solid #000000",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        paddingBottom: 40,
                      },
                      content: "Central agent's representative",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "33%",
                    padding: 5,
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        paddingBottom: 40,
                      },
                      content: "Stakeholder's representative",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default jsonView;
