import { Card, CardContent, Typography } from "@material-ui/core";
import "./assets/css/pack.css";

import React from "react";
import { Button, Image } from "react-bootstrap";
import TokensIcon from "./assets/icons/tokens.png";
import { useTranslation } from "react-i18next";

export default function Pack({ title, subtitle, content, price }) {
  const { t } = useTranslation();

  return (
    <Card className="pack-card">
      <CardContent>
        <div className="pack-content">
          <Typography className="pack-title">{title}</Typography>
          <Image src={TokensIcon} width={100} />
          <Typography className="pack-content">{content}</Typography>
          <Typography className="pack-price">{price}</Typography>
          <Button className="pack-btn">{t("Buy")}</Button>
        </div>
      </CardContent>
    </Card>
  );
}
