import React, { useMemo, useState } from "react";
import { vesselServices } from "../services/vessels.services";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import AssociateUserVessel from "./AssociateUserVessel";
import { Loader } from "../../_components";
import classes from "../assets/css/vessels.module.css";
import AssociatedUsersList from "./AssociatedUsersList";
import { useDispatch } from "react-redux";
import { alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";
import { history } from "../../_helpers";

export default function EditVessel({ id, associate }) {
  const [vessel, setVessel] = useState({});
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  useMemo(async () => {
    const response = await vesselServices.getVesselById(id);
    setVessel(response?.vessel);
    setIsLoading(false);
  }, [id]);

  const [showAssociateModal, setShowAssociateModal] = useState(associate);
  const [showUsersListModal, setShowUsersListModal] = useState(false);

  const dispatch = useDispatch();

  const toggleAssociateModal = () => {
    setShowAssociateModal(!showAssociateModal);
  };

  const toggleUsersListModal = () => {
    setShowUsersListModal(!showUsersListModal);
  };

  const handleChange = (e) => {
    setVessel({ ...vessel, [e.target.name]: e.target.value });
  };

  const handleChangeFloat = (e) => {
    const converted = parseFloat(e.target.value);
    setVessel({ ...vessel, [e.target.name]: converted });
  };

  const handleChangeNumber = (e) => {
    const converted = parseInt(e.target.value);
    setVessel({ ...vessel, [e.target.name]: converted });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await vesselServices.update(vessel, id);
      dispatch(alertActions.setAlert(t(response), alertConstants.SUCCESS));

      setIsLoading(false);
    } catch (error) {
      dispatch(alertActions.setAlert(t(error), alertConstants.ERROR));
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    history.push("/vessels");
  };

  return (
    <>
      <Loader isShown={isLoading} />

      <AssociateUserVessel
        vesselId={id}
        toggle={toggleAssociateModal}
        show={showAssociateModal}
        sheetName={vessel?.sheetName}
        isLoading={isLoading}
      />

      <AssociatedUsersList
        vesselId={id}
        sheetName={vessel?.sheetName}
        toggle={toggleUsersListModal}
        show={showUsersListModal}
        toggleAdd={toggleAssociateModal}
      />

      <div className={classes?.formHeader}>
        <h1 className="page-title">{t("Edit vessel")}</h1>
      </div>
      <div className={classes.buttonsGroup}>
        <Button onClick={toggleAssociateModal}>
          <i className="fa fa-user-plus"></i> {t("Associate user")}
        </Button>
        <Button onClick={toggleUsersListModal}>
          <i className="fa fa-anchor"></i> {t("View associated users")}
        </Button>
      </div>
      <Form>
        <Form.Group>
          <Form.Label>
            {t("Name")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Sheet name")}
            required
            name="sheetName"
            maxLength={40}
            value={vessel?.sheetName}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Name of the vessel")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Name of the vessel")}
            required
            name="vesselName"
            maxLength={30}
            value={vessel?.vesselName}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("IMO number")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("IMO number")}
            required
            name="vesselIMO"
            maxLength={20}
            value={vessel?.vesselIMO}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Port of registry")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Port of registry")}
            required
            name="vesselRegistryPort"
            maxLength={30}
            value={vessel?.vesselRegistryPort}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Flag")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Flag")}
            required
            name="vesselFlag"
            maxLength={20}
            value={vessel.vesselFlag}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Type")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Group>
            <Form.Check
              type="radio"
              label="M/Y"
              value={"M/Y"}
              required
              name="vesselType"
              onChange={handleChange}
              checked={vessel?.vesselType === "M/Y"}
            />
            <Form.Check
              type="radio"
              label={"S/Y"}
              value={"S/Y"}
              required
              name="vesselType"
              onChange={handleChange}
              checked={vessel?.vesselType === "S/Y"}
            />{" "}
          </Form.Group>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Length overall")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="number"
            placeholder={t("xxx.xx")}
            required
            name="vesselLOA"
            value={vessel?.vesselLOA}
            onChange={handleChangeFloat}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Maximum number of Passengers with staterooms")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="number"
            placeholder={t("xx")}
            required
            name="vesselMaxPassengersWithStaterooms"
            value={vessel?.vesselMaxPassengersWithStaterooms}
            onChange={handleChangeNumber}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Day Passengers on board")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="number"
            placeholder={t("xx")}
            required
            name="vesselDayPassengersOnBoard"
            value={vessel.vesselDayPassengersOnBoard}
            onChange={handleChangeNumber}
          />
        </Form.Group>
        <hr />

        <h1 className="page-subtitle">{t("Service Provider")}</h1>
        <Form.Group>
          <Form.Label>
            {t("Service provider")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Service provider")}
            required
            name="vesselServiceProvider"
            maxLength={40}
            value={vessel?.vesselServiceProvider}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            {t("Service provider address")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Service provider address")}
            required
            name="vesselServiceProviderAddress"
            maxLength={150}
            value={vessel?.vesselServiceProviderAddress}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Service provider Company ID")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Service provider CompanyID")}
            required
            name="vesselServiceProviderCompany"
            maxLength={40}
            value={vessel?.vesselServiceProviderCompany}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Service provider insurance reference")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Service provider insurance reference")}
            required
            name="vesselServiceProviderInsuranceRef"
            maxLength={30}
            value={vessel?.vesselServiceProviderInsuranceRef}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Service provider VAT number")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Service provider VAT number")}
            required
            name="vesselServiceProviderVAT"
            maxLength={20}
            value={vessel?.vesselServiceProviderVAT}
            onChange={handleChange}
          />
        </Form.Group>
        <hr />

        <h1 className="page-subtitle">{t("Central Agent")}</h1>
        <Form.Group>
          <Form.Label>{t("Central agent")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent")}
            name="vesselCentralAgent"
            maxLength={40}
            value={vessel?.vesselCentralAgent}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Central agent address")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent address")}
            name="vesselCentralAgentAddress"
            maxLength={75}
            value={vessel?.vesselCentralAgentAddress}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Central agent Company ID")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent Company ID")}
            name="vesselCentralAgentCompany"
            maxLength={40}
            value={vessel?.vesselCentralAgentCompany}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Central agent insurance reference")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent insurance reference")}
            name="vesselCentralAgentInsuranceRef"
            maxLength={30}
            value={vessel?.vesselCentralAgentInsuranceRef}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Central agent VAT number")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent VAT number")}
            name="vesselCentralAgentVAT"
            maxLength={20}
            value={vessel?.vesselCentralAgentVAT}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Central agent MYBA id")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent MYBA id")}
            name="vesselCentralAgentMYBA"
            maxLength={10}
            value={vessel?.vesselCentralAgentMYBA}
            onChange={handleChange}
          />
        </Form.Group>
        <hr />
        <h1 className="page-subtitle">{t("Stakeholder")}</h1>
        <Form.Group>
          <Form.Label>{t("Stakeholder")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder")}
            name="vesselStakeholder"
            maxLength={40}
            value={vessel?.vesselStakeholder}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder address")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder address")}
            name="vesselStakeholderAddress"
            maxLength={75}
            value={vessel?.vesselStakeholderAddress}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder Company ID")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder Company ID")}
            name="vesselStakeholderCompany"
            maxLength={40}
            value={vessel?.vesselStakeholderCompany}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder insurance reference")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder insurance reference")}
            name="vesselStakeholderInsuranceRef"
            maxLength={30}
            value={vessel?.vesselStakeholderInsuranceRef}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder VAT number")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder VAT number")}
            name="vesselStakeholderVAT"
            maxLength={20}
            value={vessel?.vesselStakeholderVAT}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder MYBA id")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder MYBA id")}
            name="vesselStakeholderMYBA"
            maxLength={10}
            value={vessel?.vesselStakeholderMYBA}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder financial guarantee")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder financial guarantee")}
            name="vesselStakeholderFinancialGuarantee"
            maxLength={30}
            value={vessel?.vesselStakeholderFinancialGuarantee}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Special conditions")}</Form.Label>
          <Form.Control
            as="textarea"
            type="text"
            name="vesselSpecialConditions"
            rows={50}
            value={vessel?.vesselSpecialConditions}
            onChange={handleChange}
          />
        </Form.Group>
        <div className={classes.formButtonGroup}>
          <Button variant="primary" onClick={handleSubmit}>
            {t("Save")}
          </Button>
          <Button variant="primary" onClick={handleCancel}>
            {t("Cancel")}
          </Button>
        </div>
      </Form>
    </>
  );
}
