import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vesselServices } from "../services/vessels.services";
import { useDispatch } from "react-redux";
import { alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";
import classes from "../assets/css/vessels.module.css";

export default function RemoveVessel({
  vesselId,
  toggle,
  show,
  setVessels,
  userId,
  sheetName,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const response = await vesselServices?.remove(vesselId);
      const vessels = await vesselServices?.getVesselsByAuthor(userId);
      setVessels(vessels);
      dispatch(alertActions.setAlert(t(response), alertConstants.SUCCESS));
      setIsLoading(false);
      toggle();
    } catch (error) {
      dispatch(alertActions.setAlert(t(error), alertConstants.ERROR));
    }
  };

  return (
    <>
      <Modal show={show} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title className={classes.modalTitle}>
            {t("Delete vessel")}
            <br />
            {sheetName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{t("Are you sure you want to delete this vessel?")}</span>
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <Button onClick={handleDelete} disabled={isLoading}>
            {t("Delete")}
          </Button>
          <Button onClick={toggle}>{t("Cancel")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
