import * as React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import classes from "./assets/css/MyDatePicker.module.css";
export default function MyDatePicker({ label, selectedDate, setSelectedDate }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={selectedDate}
        onChange={(newValue) => setSelectedDate(newValue)}
        renderInput={(params) => (
          <TextField color="warning" size="small" {...params} />
        )}
        className={classes["MuiInputBase-formControl"]}
      />
    </LocalizationProvider>
  );
}
