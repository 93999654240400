import React, { useEffect, useState } from "react";

import { Navbar, Nav, Button } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Link, useLocation } from "react-router-dom";

import { userActions } from "../../_actions";

import "./PrivateHeader.scss";
import { companiesService } from "../../_services";

export const PrivateHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.authentication.user);
  const [currentCompany, setCurrentCompany] = useState({});
  const askLogout = () => {
    dispatch(userActions.logout());
  };

  useEffect(() => {
    const fetchCompany = async () => {
      const res = await companiesService.find(user.company.id);
      setCurrentCompany(res);
    };

    if (user?.company?.id) {
      fetchCompany();
    }
  }, []);
  return (
    <header>
      <Navbar variant="dark" expand="lg">
        <Navbar.Toggle aria-controls="private-navbar-nav" />

        <Navbar.Collapse id="private-navbar-nav">
          <Nav>
            <Nav.Link
              as={Link}
              to="/"
              className={location.pathname === "/" ? "active" : ""}
            >
              <span>{t("Home")}</span>
            </Nav.Link>

            {currentCompany?.cdtPermit === true && (
              <Nav.Link
                as={Link}
                to="/contracts/folders"
                className={
                  location.pathname.startsWith("/contracts") ? "active" : ""
                }
              >
                <span>{t("My Transport agreements")}</span>
              </Nav.Link>
            )}

            {currentCompany?.cdcPermit === true && (
              <Nav.Link
                as={Link}
                to="/charters/folders"
                className={
                  location.pathname.startsWith("/charters") ? "active" : ""
                }
              >
                <span>{t("My Charter agreements")}</span>
              </Nav.Link>
            )}
            <Nav.Link
              as={Link}
              to="/vessels"
              className={
                location.pathname.startsWith("/vessels") ? "active" : ""
              }
            >
              <span>{t("My vessels")}</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/notices"
              className={
                location.pathname.startsWith("/notices") ? "active" : ""
              }
            >
              <span>{t("Notices")}</span>
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/check-contract"
              className={
                location.pathname.startsWith("/check-contract") ? "active" : ""
              }
            >
              <span>{t("Check agreement")}</span>
            </Nav.Link>

            {i18n.language === "en" && (
              <a
                href="https://www.ecpy.org/en_GB/transport-agreement"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link"
              >
                <span>Transport Agreement</span>
              </a>
            )}

            {i18n.language === "fr" && (
              <a
                href="https://www.ecpy.org/transport-agreement"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link"
              >
                <span>Contrat de Transport</span>
              </a>
            )}

            <Nav.Link
              as={Link}
              to="/contact"
              className={
                location.pathname.startsWith("/contact") ? "active" : ""
              }
            >
              <span>{t("Contact")}</span>
            </Nav.Link>

            <a
              href="http://ecpy.org"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              <span>{t("About ecpy.org")}</span>
            </a>
          </Nav>

          <Nav
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              top: "15px",
              right: "15px",
            }}
          >
            <div
              className="btn-wrapper mt-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Button
                variant="outline-light"
                style={{ width: "100%", margin: 0, marginBottom: "5px" }}
              >
                <Link to="/my-account" style={{ fontSize: "12px" }}>
                  {t("My account")}
                </Link>
              </Button>

              <Button
                onClick={askLogout}
                variant="outline-light"
                className="logoutBtn"
                style={{ width: "100%", margin: 0 }}
              >
                {t("Logout")}
              </Button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};
