import { v4 as uuid } from 'uuid'
import { alertConstants } from '../_constants'

export const alertActions = {
  setAlert,
}

function setAlert(message, type, timeout = 5000) {
  return (dispatch) => {
    const id = uuid()
    dispatch({
      type: alertConstants.SET_ALERT,
      payload: {
        message,
        type,
        id,
      },
    })

    setTimeout(
      () => dispatch({ type: alertConstants.REMOVE_ALERT, payload: id }),
      timeout
    )
  }
}
