import nacl, { box, randomBytes } from "tweetnacl";
import {
  decodeUTF8,
  encodeUTF8,
  encodeBase64,
  decodeBase64,
} from "tweetnacl-util";
import naclUtil from "tweetnacl-util";

export function encrypt(message, publicKey, privateKey) {
  const decodedPrivateKey = decodeBase64(privateKey);
  const decodedPublicKey = decodeBase64(publicKey);

  const nonce = randomBytes(box.nonceLength);
  const messageUint8 = decodeUTF8(message);

  const encrypted = box(
    messageUint8,
    nonce,
    decodedPublicKey,
    decodedPrivateKey
  );

  const fullMessage = new Uint8Array(nonce.length + encrypted.length);
  fullMessage.set(nonce);
  fullMessage.set(encrypted, nonce.length);

  return encodeBase64(fullMessage);
}

export function decrypt(message, publicKey, privateKey) {
  if (message) {
    const decodedPrivateKey = decodeBase64(privateKey);
    const decodedPublicKey = decodeBase64(publicKey);

    const messageAsUint8 = decodeBase64(message);
    const nonce = messageAsUint8.slice(0, box.nonceLength);

    const trueMessage = messageAsUint8.slice(box.nonceLength, message?.length);
    const decrypted = box.open(
      trueMessage,
      nonce,
      decodedPublicKey,
      decodedPrivateKey
    );

    return encodeUTF8(decrypted);
  }
}

export function isMatchingKeyPair(privateKeyBase64, publicKeyBase64) {
  try {
    const privateKey = naclUtil.decodeBase64(privateKeyBase64);
    const publicKey = naclUtil.decodeBase64(publicKeyBase64);

    if (!privateKey) {
      return false;
    }
    if (privateKey.length !== 32 || publicKey.length !== 32) {
      console.error("Invalid key length");
      return false;
    }

    const derivedPublicKey =
      nacl.box.keyPair.fromSecretKey(privateKey).publicKey;
    return derivedPublicKey.every((byte, index) => byte === publicKey[index]);
  } catch (e) {
    console.error("Error during key derivation:", e?.message);
    return false;
  }
}

export function decryptValue(encryptedBase64) {
  try {
    const serverPrivateKeyBase64 = process.env.REACT_APP_SERVER_PRIVATE_KEY;
    const serverPrivateKey = naclUtil.decodeBase64(serverPrivateKeyBase64);

    const clientPublicKeyBase64 = process.env.REACT_APP_SERVER_PUBLIC_KEY;
    const clientPublicKey = naclUtil.decodeBase64(clientPublicKeyBase64);

    if (!serverPrivateKey || !clientPublicKey) {
      throw new Error(
        "Invalid keys: Either the private key or public key is null or undefined."
      );
    }

    const encrypted = naclUtil.decodeBase64(encryptedBase64);

    if (encrypted.length < 24) {
      throw new Error("Ciphertext is too short (missing nonce).");
    }

    const nonce = encrypted.slice(0, 24);
    const ciphertext = encrypted.slice(24);

    const decrypted = nacl.box.open(
      ciphertext,
      nonce,
      clientPublicKey,
      serverPrivateKey
    );

    return naclUtil.encodeUTF8(decrypted);
  } catch (error) {
    console.error("Decryption error:", error.message);
    return null;
  }
}

export const encryptInfo = (data, publicKey, secretKey) => {
  const nonce = nacl.randomBytes(nacl.box.nonceLength);
  const encryptedData = nacl.box(
    new TextEncoder().encode(data),
    nonce,
    decodeBase64(publicKey),
    decodeBase64(secretKey)
  );
  const combinedData = new Uint8Array(nonce.length + encryptedData.length);
  combinedData.set(nonce);
  combinedData.set(encryptedData, nonce.length);

  return encodeBase64(combinedData);
};

export const decryptInfo = (
  encryptedData,
  publicKeyBase64,
  privateKeyBase64
) => {
  try {
    const encryptedBytes = decodeBase64(encryptedData);

    const nonce = encryptedBytes.slice(0, nacl.box.nonceLength);
    const cipherText = encryptedBytes.slice(nacl.box.nonceLength);


    const decrypted = nacl.box.open(
      cipherText,
      nonce,
      decodeBase64(publicKeyBase64),
      decodeBase64(privateKeyBase64)
    );

    const decodedString = new TextDecoder().decode(decrypted);

    return decodedString;
  } catch (error) {
    console.error("Decryption error:", error);
    throw error;
  }
};
