import { authHeader } from "../../_helpers";
import { ApiService } from "../../_services/api";
import axios from "axios";

const apiService = new ApiService();
apiService.setApiUrl("/vessel");

export const vesselServices = {
  add,
  getVesselsByAuthor,
  getVesselById,
  associateUser,
  getUsersByVessel,
  update,
  remove,
  copy,
  transfer,
  getVesselsByUser,
  unlinkUser
};

async function add(payload, author) {
  try {
    const data = JSON.stringify(payload);

    const res = await axios.post(
      `${apiService.apiUrl}/new/${author}`,
      data,
      authHeader()
    );

    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function getVesselsByAuthor(author) {
  try {
    const res = await axios.get(
      `${apiService.apiUrl}/list/${author}`,
      authHeader()
    );

    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function getVesselById(id) {
  try {
    const res = await axios.get(`${apiService.apiUrl}/${id}`, authHeader());

    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function associateUser(id, userEmail) {
  try {
    const payload = { userEmail };
    const res = await axios.post(
      `${apiService.apiUrl}/associate/${id}`,
      payload,
      authHeader()
    );

    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function getUsersByVessel(id) {
  try {
    const res = await axios.get(
      `${apiService.apiUrl}/users/${id}`,
      authHeader()
    );

    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function update(payload, id) {
  try {
    const data = JSON.stringify(payload);

    const res = await axios.put(
      `${apiService.apiUrl}/update/${id}`,
      data,
      authHeader()
    );

    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function remove(id) {
  try {
    const res = await axios.delete(
      `${apiService.getApiUrl()}/delete/${id}`,
      authHeader()
    );

    return res.data;
  } catch (err) {
    throw this.manageErrors(err);
  }
}

async function copy(id, author, newSheetName) {
  try {
    const payload = JSON.stringify({ newSheetName });

    const res = await axios.post(
      `${apiService.apiUrl}/copy/${id}/${author}`,
      payload,
      authHeader()
    );

    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function transfer(id, prevAuthor, nextAuthor) {
  try {
    const payload = JSON.stringify({ newAuthor: nextAuthor });

    const res = await axios.patch(
      `${apiService.apiUrl}/transfer/${id}/${prevAuthor}`,
      payload,
      authHeader()
    );

    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function getVesselsByUser(userId) {
  try {
    const res = await axios.get(
      `${apiService.apiUrl}/list/by-user/${userId}`,
      authHeader()
    );

    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function unlinkUser(id, userId) {
  try {
    const res = await axios.post(
      `${apiService.apiUrl}/${id}/unlink/${userId}`,
      null,
      authHeader()
    );

    return res.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}
