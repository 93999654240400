import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vesselServices } from "../services/vessels.services";
import { useDispatch } from "react-redux";
import { alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";
import classes from "../assets/css/vessels.module.css";

export default function AssociateUserVessel({
  vesselId,
  toggle,
  show,
  isLoading = false,
}) {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState("");
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setUserEmail(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await vesselServices?.associateUser(vesselId, userEmail);
      dispatch(alertActions.setAlert(t(response), alertConstants.SUCCESS));
      setUserEmail("");
      toggle();
    } catch (error) {
      dispatch(alertActions.setAlert(t(error), alertConstants.ERROR));
    }
  };
  return (
    <Modal show={show && !isLoading} onHide={toggle}>
      <Modal.Header closeButton>
        <span className={classes?.modalTitle}>{t("Associate user")}</span>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>{t("Enter user E-mail")}</Form.Label>
            <Form.Control
              type="email"
              placeholder={"example@domain.fr"}
              required
              name="userEmail"
              value={userEmail}
              onChange={handleChange}
              className={classes["email-control"]}
            />
          </Form.Group>
          <Button onClick={handleSubmit}>{t("Associate")}</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
