import { decrypt, getPublicKey } from "../../_helpers";

import jsonView from "../views/TemplateViewJson";
import jsonViewCharter from "../views/TemplateCharterJson";
import itineraryJsonView from "../views/ItineraryJsonView";
import passengersJsonView from "../views/PassengersJsonView";
import agencyAgreementJsonView from "../views/AgencyAgreementJsonView";
import riderAgreementJsonView from "../views/RiderAgreementJsonView";
import addendumJsonView from "../views/AddendumJsonView";
import dayContractJsonView from "../views/DayContractView";
import AddendumDay from "../views/AddendumDayJsonView";
import AddendumCharter from "../views/AddendumCharterJsonView";
export const createDocumentObj = (
  contract,
  contractFolder,
  moment,
  user,
  privateKey,
  t
) => {
  // Set moment locale
  let locale = contractFolder?.lang;
  if (!locale) {
    locale = "en";
  }
  moment.locale(locale);

  let publicKeyToUse = getPublicKey(user, contractFolder);
  const decryptSecondArg = contractFolder?.isOwnerNew
    ? contractFolder?.newOwnerPublicKey
    : contractFolder?.sharedBrokers.length > 0 ||
      contractFolder.createdFromShared
    ? contractFolder.owner.publicKey
    : publicKeyToUse;
    
  const decryptThirdArg = contractFolder?.isOwnerNew
    ? contractFolder?.newOwnerSecretKey
    : contractFolder?.sharedBrokers.length > 0 ||
      contractFolder.createdFromShared
    ? contractFolder.owner.publicKey
    : privateKey;

  let decryptedContract = {
    name: decrypt(contractFolder.name, decryptSecondArg, decryptThirdArg),
    template: contract.template,
    contractFolder: contractFolder,
  };

  let fieldValues = [];

  let status =
    decryptedContract["contractFolder"]["status"] === "draft"
      ? "DRAFT"
      : "ECPY COPYRIGHT";

  /*
   * Populate field values with broker informations
   */

  fieldValues["status"] = {
    name: "status",
    value: status,
  };

  fieldValues["statusContract"] = {
    name: "statusContract",
    value: status,
  };

  fieldValues["broker"] = {
    name: "broker",
    value: status,
  };

  fieldValues["brokerAddr"] = {
    name: "brokerAddr",
    value:
      user.company.address1 +
      " " +
      user.company.address2 +
      " " +
      user.company.zipcode +
      " " +
      user.company.city,
  };

  fieldValues["brokerCompanyIdOld"] = {
    name: "brokerCompanyIdOld",
    value:
      user.company.registrationNumber + " " + user.company.registrationCountry,
  };

  fieldValues["brokerMYBA"] = {
    name: "brokerMYBA",
    value: user.company.MYBAid,
  };

  // Add checkbox fields to display label
  for (const field of contract.template.fields) {
    if (field?.type === "checkbox") {
      let checkboxField = {
        name: field?.name,
        label: field?.label,
        order: field?.orderInTemplate,
        value: "",
      };

      fieldValues[field?.id] = checkboxField;
    } else if (field?.type === "radio") {
      let radioField = {
        name: field?.name,
        label: field?.label,
        options: field?.options,
        order: field?.orderInTemplate,
        value: "",
      };

      fieldValues[field?.id] = radioField;
    }
  }

  let agreementField = null;

  for (const fieldValue of contract.fieldValues) {
    if (
      fieldValue.value !== "" ||
      fieldValue.field?.name.startsWith("passenger")
    ) {
      let field = {
        name: fieldValue.field?.name,
        label: fieldValue.field?.label,
        order: fieldValue.field?.orderInTemplate,
        value: decrypt(fieldValue.value, decryptSecondArg, decryptThirdArg),
        formatNumber: fieldValue.field?.formatNumber
          ? fieldValue.field?.formatNumber
          : false,
      };

      if (field?.name === "agreementUniqueNumber") {
        agreementField = field;
      }

      if (fieldValue.field?.type === "date") {
        field.value = moment(field?.value).format("ll");
      }

      /*if (fieldValue.field?.type === 'time') {
        field?.value = moment(field?.value).format('HH:mm')
      }*/

      if (fieldValues[fieldValue.field?.id]) {
        fieldValues[fieldValue.field?.id] = {
          ...fieldValues[fieldValue.field?.id],
          ...field,
        };
      } else {
        fieldValues[fieldValue.field?.id] = field;
      }
    }
  }

  if (!agreementField) {
    const goodContract = contractFolder.contracts.find(
      (c) =>
        c.template.codeName === "baseTemplate" ||
        c.template.codeName === "baseTemplateDay"
    );
    let idField = goodContract.fieldValues.find(
      (f) => f.field?.name === "agreementUniqueNumber"
    );

    if (idField) {
      const newField = {
        name: "agreementUniqueNumber",
        value: decrypt(idField.value, decryptSecondArg, decryptThirdArg),
      };
      fieldValues[idField.field?.id] = newField;
    }
  }

  // Add contract version
  let agreementInFieldValues = null;

  for (let key in fieldValues) {
    const fieldValue = fieldValues[key];
    if (fieldValue.name === "agreementUniqueNumber") {
      agreementInFieldValues = fieldValue;
      agreementInFieldValues.id = key;
      break;
    }
  }

  if (agreementInFieldValues) {
    fieldValues[agreementInFieldValues.id].value =
      agreementInFieldValues.value + " v." + contractFolder.version;
  }

  if (contract.template.codeName === "baseTemplateDay") {
    // Find addendum
    const addendum = contractFolder.contracts.find(
      (c) => c.template.codeName === "addendumTemplateDay"
    );

    if (addendum) {
      for (let fv of addendum.fieldValues) {
        let field = {
          name: fv.field?.name,
          label: fv.field?.label,
          order: fv.field?.orderInTemplate,
          value: decrypt(fv.value, decryptSecondArg, decryptThirdArg),
          formatNumber: fv.field?.formatNumber ? fv.field?.formatNumber : false,
        };

        fieldValues[fv.field?.id] = field;
      }
    }
  }

  decryptedContract.fields = fieldValues;

  // Add new fields

  let addendumClient1 = "";
  let addendumServiceProvider1 = "";

  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].value !== "" &&
      decryptedContract.fields[key].name === "addendumClient"
    ) {
      addendumClient1 = decryptedContract.fields[key].value;
    }
  });
  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].value !== "" &&
      decryptedContract.fields[key].name === "addendumServiceProvider"
    ) {
      addendumServiceProvider1 = decryptedContract.fields[key].value;
    }
  });

  decryptedContract.fields = {
    ...decryptedContract.fields,
    addendumClient1: { name: "addendumClient1", value: addendumClient1 },
    addendumServiceProvider1: {
      name: "addendumServiceProvider1",
      value: addendumServiceProvider1,
    },
  };

  // Add currency
  let val = "";
  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].value !== "" &&
      decryptedContract.fields[key].name === "Otheragreementcurrency"
    ) {
      val = decryptedContract.fields[key].value;
    }
  });
  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].name === "currencyMain" &&
      val?.length !== 0
    ) {
      decryptedContract.fields[key].value = val;
    }
  });

  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].name === "Otheragreementcurrency" &&
      decryptedContract.contractFolder.lang === "en"
    ) {
      if (decryptedContract["contractFolder"]["status"] === "draft") {
        decryptedContract.fields[key].value = "This Agreement was created on";
      } else if (decryptedContract["contractFolder"]["status"] === "contract") {
        decryptedContract.fields[key].value = "This Agreement was signed on";
      }
    } else if (
      decryptedContract.fields[key].name === "Otheragreementcurrency" &&
      decryptedContract.contractFolder.lang === "fr"
    ) {
      if (decryptedContract["contractFolder"]["status"] === "draft") {
        decryptedContract.fields[key].value =
          "Le présent Contrat a été créé le";
      } else if (decryptedContract["contractFolder"]["status"] === "contract") {
        decryptedContract.fields[key].value =
          "Le présent Contrat a été signé le";
      }
    }
  });

  let jsonViewForContract = jsonView; // Default
  let filename = decrypt(
    contractFolder.name,
    decryptSecondArg,
    decryptThirdArg
  );

  switch (contract.template.codeName) {
    case "itinerary":
      jsonViewForContract = itineraryJsonView;
      filename += "_" + t("itinerary");
      break;
    case "passengers":
      jsonViewForContract = passengersJsonView;
      filename += "_" + t("passengers");
      break;
    case "agencyAgreement":
      jsonViewForContract = agencyAgreementJsonView;
      filename += "_" + t("agency_agreement");
      break;
    case "rider":
      jsonViewForContract = riderAgreementJsonView;
      filename += "_" + t("rider");
      break;
    case "addendum":
      jsonViewForContract = addendumJsonView;
      filename += "_" + t("addendum");
      break;
    case "baseTemplateDay":
      jsonViewForContract = dayContractJsonView;
      break;
    case "AddendumDAY":
      jsonViewForContract = AddendumDay;
      filename += "_" + t("addendum (Day)");
      break;
    default:
      break;
  }

  filename += ".pdf";

  return {
    contract: decryptedContract,
    jsonView: jsonViewForContract,
    filename: filename,
  };
};

export const createCharterDocumentObj = (
  contract,
  contractFolder,
  moment,
  user,
  privateKey,
  t
) => {
  // Set moment locale
  let locale = contractFolder?.lang;
  if (!locale) {
    locale = "en";
  }
  moment.locale(locale);

  let publicKeyToUse = getPublicKey(user, contractFolder);

  let decryptedContract = {
    name: decrypt(contractFolder.name, publicKeyToUse, privateKey),
    template: contract?.template,
    contractFolder: contractFolder,
  };

  let fieldValues = [];

  /*
   * Populate field values with broker informations
   */
  fieldValues["broker"] = {
    name: "broker",
    value: user.firstname + " " + user.name,
  };

  fieldValues["brokerAddr"] = {
    name: "brokerAddr",
    value:
      user.company.address1 +
      " " +
      user.company.address2 +
      " " +
      user.company.zipcode +
      " " +
      user.company.city,
  };

  fieldValues["brokerCompanyIdOld"] = {
    name: "brokerCompanyIdOld",
    value:
      user.company.registrationNumber + " " + user.company.registrationCountry,
  };

  fieldValues["brokerMYBA"] = {
    name: "brokerMYBA",
    value: user.company.MYBAid,
  };

  // Add checkbox fields to display label
  for (const field of contract.template.fields) {
    if (field?.type === "checkbox") {
      let checkboxField = {
        name: field?.name,
        label: field?.label,
        order: field?.orderInTemplate,
        value: "",
      };

      fieldValues[field?.id] = checkboxField;
    } else if (field?.type === "radio") {
      let radioField = {
        name: field?.name,
        label: field?.label,
        options: field?.options,
        order: field?.orderInTemplate,
        value: "",
      };

      fieldValues[field?.id] = radioField;
    }
  }

  let agreementField = null;

  for (const fieldValue of contract.fieldValues) {
    if (
      fieldValue.value !== "" ||
      fieldValue.field?.name.startsWith("passenger")
    ) {
      let field = {
        name: fieldValue.field?.name,
        label: fieldValue.field?.label,
        order: fieldValue.field?.orderInTemplate,
        value: decrypt(fieldValue.value, publicKeyToUse, privateKey),
        formatNumber: fieldValue.field?.formatNumber
          ? fieldValue.field?.formatNumber
          : false,
      };

      if (field?.name === "agreementUniqueNumber") {
        agreementField = field;
      }

      if (fieldValue.field?.type === "date") {
        field.value = moment(field?.value).format("ll");
      }

      if (fieldValues[fieldValue.field?.id]) {
        fieldValues[fieldValue.field?.id] = {
          ...fieldValues[fieldValue.field?.id],
          ...field,
        };
      } else {
        fieldValues[fieldValue.field?.id] = field;
      }
    }
  }

  if (!agreementField) {
    const goodContract = contractFolder.contracts.find(
      (c) => c.template.codeName === "baseTemplateCharter"
    );

    let idField = goodContract.fieldValues.find(
      (f) => f.field?.name === "agreementUniqueNumber"
    );

    if (idField) {
      const newField = {
        name: "agreementUniqueNumber",
        value: decrypt(idField.value, publicKeyToUse, privateKey),
      };

      fieldValues[idField.field?.id] = newField;
    }
  }

  // Add contract version
  let agreementInFieldValues = null;

  for (let key in fieldValues) {
    const fieldValue = fieldValues[key];
    if (fieldValue.name === "agreementUniqueNumber") {
      agreementInFieldValues = fieldValue;
      agreementInFieldValues.id = key;
      break;
    }
  }

  if (agreementInFieldValues) {
    fieldValues[agreementInFieldValues.id].value =
      agreementInFieldValues.value + " v." + contractFolder.version;
  }

  decryptedContract.fields = fieldValues;

  let chartername1 = "";
  let chartername2 = "";
  let ownerName = "";

  let addendumClient1 = "";
  let addendumOwner = "";
  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].value !== "" &&
      decryptedContract.fields[key].name === "addendumClient"
    ) {
      addendumClient1 = decryptedContract.fields[key].value;
    }
  });

  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].value !== "" &&
      decryptedContract.fields[key].name === "addendumShipowner"
    ) {
      addendumOwner = decryptedContract.fields[key].value;
    }
  });

  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].value !== "" &&
      decryptedContract.fields[key].name === "CharterClient"
    ) {
      chartername1 = decryptedContract.fields[key].value;
      chartername2 = decryptedContract.fields[key].value;
    }
  });

  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].value !== "" &&
      decryptedContract.fields[key].name === "CharterClient" &&
      decryptedContract.contractFolder?.lang === "fr"
    ) {
      decryptedContract.fields = {
        ...decryptedContract.fields,

        enpresencede: { name: "enpresencede", value: "En presence de :" },
        lignePage2: {
          name: "lignePage2",
          value:
            "RENSEIGNEMENTS RELATIFS A L'AFFRETEMENT ET CONDITIONS FINANCIERES ",
        },
      };
    }
  });

  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].value !== "" &&
      decryptedContract.fields[key].name === "shipOwner"
    ) {
      ownerName = decryptedContract.fields[key].value;
    }
  });

  decryptedContract.fields = {
    ...decryptedContract.fields,
    chartername1: { name: "chartername1", value: chartername1 },
    chartername2: { name: "chartername2", value: chartername2 },
    ownerName: { name: "ownerName", value: ownerName },
    ownerName2: { name: "ownerName2", value: ownerName },
    addendumClient1: { name: "addendumClient1", value: addendumClient1 },
    addendumOwner: { name: "addendumOwner", value: addendumOwner },
  };

  // Add currency
  let val = "";

  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].value !== "" &&
      decryptedContract.fields[key].name === "currencyMain"
    ) {
    }

    if (
      decryptedContract.fields[key].value !== "" &&
      decryptedContract.fields[key].name === "Otheragreementcurrency"
    ) {
      val = decryptedContract.fields[key].value;
    }
  });
  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].name === "addendumCurrency" &&
      val?.length !== 0
    ) {
      decryptedContract.fields[key].value = val;
    }
  });
  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].name === "currencyMain" &&
      decryptedContract.fields[key].value === "Other"
    ) {
      decryptedContract.fields[key].value = val;
    }
    if (
      decryptedContract.fields[key].name === "currencyMain" &&
      decryptedContract.fields[key].value !== ""
    ) {
      val = decryptedContract.fields[key].value;
    }
  });

  Object.keys(decryptedContract.fields).forEach(function (key, index) {
    if (
      decryptedContract.fields[key].name === "Otheragreementcurrency" &&
      decryptedContract.contractFolder?.lang === "en"
    ) {
      if (decryptedContract["contractFolder"]["status"] === "draft") {
        decryptedContract.fields[key].value = "This Agreement was created on";
      } else if (decryptedContract["contractFolder"]["status"] === "contract") {
        decryptedContract.fields[key].value = "This Agreement was signed on";
      }
    } else if (
      decryptedContract.fields[key].name === "Otheragreementcurrency" &&
      decryptedContract.contractFolder?.lang === "fr"
    ) {
      if (decryptedContract["contractFolder"]["status"] === "draft") {
        decryptedContract.fields[key].value = "Ce contrat a été créé le";
      } else if (decryptedContract["contractFolder"]["status"] === "contract") {
        decryptedContract.fields[key].value = "Ce contrat a été signé le";
      }
    }
  });

  let jsonViewForContract = jsonViewCharter; // Default
  let filename = decrypt(contractFolder.name, publicKeyToUse, privateKey);
  switch (contract.template.codeName) {
    case "AddendumCharter":
      jsonViewForContract = AddendumCharter;
      filename += "_" + t("AddendumCharter");

      break;
    default:
      break;
  }
  filename += ".pdf";
  return {
    contract: decryptedContract,
    jsonView: jsonViewForContract,
    filename: filename,
  };
};
