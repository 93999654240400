import axios from "axios";

import { authHeader } from "../../_helpers";

const API_URL = `${process.env.REACT_APP_API_ENTRYPOINT}`;

export class ApiService {
  apiUrl = `${API_URL}`;

  setApiUrl(url) {
    this.apiUrl += url;
  }

  getApiUrl() {
    return this.apiUrl;
  }

  manageErrors(err) {
    if (err.response?.data) {
      const type = err.response.data["@type"];
      if (type && type === "ConstraintViolationList") {
        return err.response.data["hydra:description"];
      }
      if (err?.response?.data?.detail && err?.response?.data?.status !== 500) {
        return err?.response?.data?.detail;
      } else {
        return "An error has occurred. If the problem persists, please contact the administrator";
      }
    }

    return err;
  }

  async findAll(page = 1) {
    try {
      const res = await axios.get(`${this.apiUrl}?page=${page}`, authHeader());

      if (res.status === 200) {
        return res.data;
      } else {
        throw res.data["hydra:description"];
      }
    } catch (err) {
      throw this.manageErrors(err);
    }
  }

  async find(id) {
    try {
      const res = await axios.get(`${this.apiUrl}/get/${id}`, authHeader());
      //const res = await axios.get(`${this.apiUrl}/${id}`, authHeader());

      return res.data;
    } catch (err) {
      throw this.manageErrors(err);
    }
  }

  async add(item) {
    try {
      const body = JSON.stringify(item);

      const res = await axios.post(`${this.apiUrl}`, body, authHeader());

      return res.data;
    } catch (err) {
      throw this.manageErrors(err);
    }
  }

  async addBase(item) {
    try {
      const body = JSON.stringify(item);

      const res = await axios.post(`${this.apiUrl}/base`, body, authHeader());

      return res.data;
    } catch (err) {
      throw this.manageErrors(err);
    }
  }

  async addApiPlatform(item) {
    try {
      const body = JSON.stringify(item);

      const res = await axios.post(`${this.apiUrl}`, body, authHeader());

      return res.data;
    } catch (err) {
      throw this.manageErrors(err);
    }
  }

  async addMultiple(items) {
    try {
      const body = JSON.stringify(items);

      const res = await axios.post(`${this.apiUrl}`, body, authHeader());

      if (res.status === 201) {
        return res.data;
      } else {
        throw res.data["hydra:description"];
      }
    } catch (err) {
      throw this.manageErrors(err);
    }
  }

  async addGroup(data, template) {
    try {
      const body = JSON.stringify(data);

      const res = await axios.post(
        `${this.apiUrl}/group/${template}`,
        body,
        authHeader()
      );

      return res;
    } catch (err) {
      throw this.manageErrors(err);
    }
  }

  async update(item) {
    try {
      const body = JSON.stringify(item);
      const res = await axios.put(
        `${this.apiUrl}/${item.id}`,
        body,
        authHeader()
      );

      if (res.status === 200) {
        return res.data;
      } else {
        throw res.data["hydra:description"];
      }
    } catch (err) {
      throw this.manageErrors(err);
    }
  }

  async remove(id) {
    try {
      const res = await axios.delete(`${this.apiUrl}/${id}`, authHeader());

      if (res.status === 204) {
        return true;
      } else {
        throw res.data["hydra:description"];
      }
    } catch (err) {
      throw this.manageErrors(err);
    }
  }

  async removeGroup(ids) {
    try {
      const body = JSON.stringify(ids);

      const res = await axios.post(`${this.apiUrl}/delete`, body, authHeader());

      return res;
    } catch (err) {
      throw this.manageErrors(err);
    }
  }
}
