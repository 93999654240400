import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "10px 25px",
    fontFamily: "Arial",
  },

  textContent: {
    fontSize: 8,
    marginTop: 1,
    marginBottom: 1,
    fontWeight: 400,
  },

  checkboxWrapper: {
    position: "relative",
    paddingLeft: "20pt",
    paddingRight: "5pt",
  },

  checkbox: {
    border: "2pt solid #000000",
    width: "15pt",
    height: "15pt",
    position: "absolute",
    top: "2pt",
  },

  checkboxChecked: {
    border: "2pt solid #000000",
    width: "15pt",
    height: "15pt",
    backgroundColor: "#555555",
    position: "absolute",
    top: "2pt",
  },
});

const jsonView = {
  type: "page",
  items: [
    {
      type: "view",
      styles: {
        margin: 5,
        padding: 10,
        marginTop: 0,
        paddingTop: 30,
      },

      items: [
        {
          type: "text",
          styles: {
            ...styles.textContent,
            textAlign: "center",
            fontFamily: "Helvetica-Bold",
          },
          content: "CONTRAT DE TRANSPORT YACHTING A LA JOURNÉE",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
            textAlign: "center",
            fontStyle: "italic",
          },
          content: "DAY TRANSPORT AGREEMENT FOR YACHTS ",
        },

        {
          type: "view",
          styles: {
            marginLeft: "45pt",
          },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Renseignements relatifs au Navire / ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                    fontWeight: 400,
                  },
                  content: "Information about the vessel",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Nom du navire : ",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                          },
                          content: "__vesselName__",
                        },
                      ],
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "N° IMO (ou équivalent) : ",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                          },
                          content: "__imoNumber__",
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontSize: 8,
                        fontStyle: "italic",
                        marginTop: 0,
                      },
                      content: "IMO Identification number",
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "Type : ",
                      items: [],
                    },

                    {
                      type: "view",
                      specialType: "choice",
                      linkedField: "yachtType",
                      withPadding: true,
                      styles: {
                        flexDirection: "row",
                      },
                      items: [],
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    marginLeft: 10,
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        width: "50%",
                      },
                      items: [
                        {
                          type: "view",
                          styles: {
                            flexDirection: "row",
                          },
                          items: [
                            {
                              type: "view",
                              styles: {},
                              items: [
                                {
                                  type: "text",
                                  styles: {
                                    ...styles.textContent,
                                    fontFamily: "Helvetica-Bold",
                                  },
                                  content: "Longeur Hors Tout : ",
                                },

                                {
                                  type: "text",
                                  styles: {
                                    ...styles.textContent,
                                    fontStyle: "italic",
                                    fontSize: 8,
                                    marginTop: 0,
                                  },
                                  content: "Overall length",
                                },
                              ],
                            },

                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                                fontWeight: 400,
                              },
                              content: "__yachtLOADinMeters__",
                            },

                            {
                              type: "view",
                              styles: {},
                              items: [
                                {
                                  type: "text",
                                  styles: {
                                    ...styles.textContent,
                                    fontWeight: 400,
                                  },
                                  content: "(mètres)",
                                },

                                {
                                  type: "text",
                                  styles: {
                                    ...styles.textContent,
                                    fontStyle: "italic",
                                    fontSize: 8,
                                    marginTop: 0,
                                  },
                                  content: "(meters)",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },

                    {
                      type: "view",
                      styles: {
                        width: "50%",
                      },
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontFamily: "Helvetica-Bold",
                          },
                          content: "Pavillon",
                          items: [
                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                                fontWeight: 400,
                              },
                              content: "__flagState__",
                            },
                          ],
                        },

                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontStyle: "italic",
                            fontSize: 8,
                            marginTop: 0,
                          },
                          content: "Flag",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          styles: {
            padding: 5,
            marginTop: 2,
            marginBottom: 2,
            border: "1pt solid #000000",
          },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Client : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__client__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Addresse / Address : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content:
                    "__clientAddress1__ __clientAddress2__ __clientAddress3__",
                },
              ],
            },
          ],
        },

        {
          type: "view",
          styles: {
            padding: 5,
            marginTop: 2,
            marginBottom: 2,
            border: "1pt solid #000000",
          },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Prestataire : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__serviceProvider__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontStyle: "italic",
                fontSize: 8,
                marginTop: 0,
              },
              content: "Service Provider",
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Addresse / Address : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content:
                    "__serviceProviderAddress1__ __serviceProviderAddress2__ ",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Assurance : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__serviceProviderInsuranceReference__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontStyle: "italic",
                fontSize: 8,
                marginTop: 0,
              },
              content: "Insurance",
            },
          ],
        },

        {
          type: "view",
          styles: {
            padding: 5,
            marginTop: 2,
            marginBottom: 2,
            border: "1pt solid #000000",
          },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Courtier : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__brokerName__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontStyle: "italic",
                fontSize: 8,
                marginTop: 0,
              },
              content: "Broker",
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Addresse / Address : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__brokerAddress1__ __brokerAddress2__ ",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Assurance : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__brokerInsurance__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontStyle: "italic",
                fontSize: 8,
                marginTop: 0,
              },
              content: "Insurance",
            },
          ],
        },

        {
          type: "view",
          styles: {
            padding: 5,
            marginTop: 2,
            marginBottom: 2,
            border: "1pt solid #000000",
          },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Agent central : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__centralAgent__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontStyle: "italic",
                fontSize: 8,
                marginTop: 0,
              },
              content: "Central agent",
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Addresse / Address : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__centralAgentAddress1__ __centralAgentAddress2__ ",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Assurance : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__centralAgentInsurance__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontStyle: "italic",
                fontSize: 8,
                marginTop: 0,
              },
              content: "Insurance",
            },
          ],
        },

        {
          type: "view",
          styles: {
            padding: 5,
            marginTop: 2,
            marginBottom: 2,
            border: "1pt solid #000000",
          },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Séquestre : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__stakeholder__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontStyle: "italic",
                fontSize: 8,
                marginTop: 0,
              },
              content: "Service Provider",
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Addresse / Address : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__stakeholderAddress1__ __stakeholderAddress2__ ",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Assurance : ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                  },
                  content: "__stakeHolderInsuranceReference__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontStyle: "italic",
                fontSize: 8,
                marginTop: 0,
              },
              content: "Insurance",
            },
          ],
        },

        {
          type: "view",
          styles: {
            border: "1pt solid #000000",
            padding: 5,
          },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "DEFINITION DE LA NAVIGATION ",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                    fontStyle: "italic",
                  },
                  content: "CRUISE PARTICULARS",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Du",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                            fontSize: 8,
                          },
                          content: "(Port d'embarquement jour et heure) : ",
                        },

                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                          },
                          content:
                            "__embarkationPort__ __cruiseStartDate__ __cruiseStartTime__",
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "From (Port of embarkation, date and time)",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Au ",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                            fontStyle: "italic",
                          },
                          content: "(Port de débarqu., jour et heure) : ",
                        },

                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                          },
                          content:
                            "__disembarkationPort__ __cruiseEndDate__ __cruiseEndTime__",
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "To (Port of disembark., date and time)",
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Port initial (préacheminement) : ",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                          },
                          content: "__deliveryPort__",
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "Port of delivery",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Port final (postacheminement) : ",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                          },
                          content: "__redeliveryPort__",
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "Port of redelivery",
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {},
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Zone de navigation & itinéraire :",
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontWeight: 400,
                      },
                      content: "__cruisingArea__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                    fontSize: 8,
                    marginTop: 0,
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontWeight: 400,
                      },
                      content: "Cruising area & Itinerary",
                    },
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        position: "absolute",
                        marginLeft: 127,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "__itineraryDay__",
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Nombre maximum de Passagers embarqués",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                          },
                          content: "__paxNb__",
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "Maximum number of Passengers on board",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Durée de navigation (heures) en route  : ",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                          },
                          content: "__totalHoursOfNavigation__",
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "Time of navigation (hours, vessel underway)",
                    },
                  ],
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
              },
              content: "Le Contrat n'inclut pas d'hébergement de nuit à bord.",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                  },
                  content:
                    "The Agreement does not include night accomodation on board.",
                },
              ],
            },
          ],
        },

        {
          type: "view",
          styles: {
            border: "1pt solid #000000",
            padding: 5,
          },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "PRIX DE LA PRESTATION",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontWeight: 400,
                    fontStyle: "italic",
                  },
                  content: "/ SERVICE PACKAGE PRICE",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    marginRight: 15,
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        flexDirection: "row",
                      },
                      items: [
                        {
                          type: "view",
                          styles: {},
                          items: [
                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                                fontFamily: "Helvetica-Bold",
                              },
                              content: "Prix total forfaitaire du voyage",
                              items: [
                                {
                                  type: "text",
                                  styles: {
                                    ...styles.textContent,
                                    fontWeight: 400,
                                  },
                                  content: "__totalPriceExclVat__ ",
                                },
                              ],
                            },

                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                                fontStyle: "italic",
                                fontSize: 8,
                                marginTop: 0,
                              },
                              content: "All-inclusive price of the voyage",
                            },
                          ],
                        },

                        {
                          type: "view",
                          styles: {},
                          items: [
                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                                fontFamily: "Helvetica-Bold",
                              },
                              content: " € HORS TVA",
                            },

                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                                fontStyle: "italic",
                                fontSize: 8,
                                marginTop: 0,
                              },
                              content: "€ EXCL VAT",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {},
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Taux de TVA",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                          },
                          content: "__vatRate__",
                        },

                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontFamily: "Helvetica-Bold",
                          },
                          content: "%",
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "VAT rate",
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                  },
                  items: [
                    {
                      type: "view",
                      styles: {},
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontFamily: "Helvetica-Bold",
                          },
                          content: "Montant de la TVA",
                          items: [
                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                                fontWeight: 400,
                              },
                              content: "__vatAmount__",
                            },

                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                                fontFamily: "Helvetica-Bold",
                              },
                              content: "€",
                            },
                          ],
                        },

                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontStyle: "italic",
                            fontSize: 8,
                            marginTop: 0,
                          },
                          content: "VAT amount",
                        },
                      ],
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Prix total",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontWeight: 400,
                          },
                          content: "__totalPriceIncVat__",
                        },

                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontFamily: "Helvetica-Bold",
                          },
                          content: "€ TTC",
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "All-inclusive price of the voyage",
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                borderTop: "1pt solid #000000",
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                    borderRight: "1pt solid #000000",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Restauration à bord",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontStyle: "italic",
                            fontSize: 8,
                          },
                          content: "/ Catering on board",
                        },
                      ],
                    },

                    {
                      type: "view",
                      styles: {},
                      items: [
                        {
                          type: "view",
                          specialType: "choice",
                          linkedField: "onboardCatering",
                          withPadding: true,
                          styles: {},
                          items: [],
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "MONTANT TOTAL DU PAIMEMENT",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontStyle: "italic",
                            fontSize: 8,
                          },
                          content: "/ TOTAL AMOUNT OF PAYMENT",
                        },

                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                          },
                          content: " : __totalAmountPayment__ €",
                        },
                      ],
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "50%",
                    padding: 5,
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Avance sur frais : __advanceProvisioning__ €",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "Advance provisioning allowance (APA)",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "Dépôt de garantie : __securityDeposit__ €",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "Security deposit",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          attrs: { wrap: false },
          styles: {
            border: "1pt solid #000000",
            padding: 5,
          },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "MODALITES DE VERSEMENT",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                    fontSize: 8,
                  },
                  content: "/ TERMS OF PAYMENT",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
              },
              content: "__paymentTermsField__",
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Moyens de paiement",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                    fontSize: 8,
                  },
                  content: "/ Means of payment",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content: "__paymentMeans__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "DONNEES BANCAIRES EN VUE DU VIREMENT",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                    fontSize: 8,
                  },
                  content: "/ Bank transfer details",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Banque",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                    fontSize: 8,
                  },
                  content: "/ Bank",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content: "__bankDesignation__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Nom du compte",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                    fontSize: 8,
                  },
                  content: "/ Account name",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content: "__bankAccountName__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "Adresse",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                    fontSize: 8,
                  },
                  content: "/ Address",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content: "__bankAddress1__ __bankAddress2__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "IBAN",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content: "__bankIban__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontFamily: "Helvetica-Bold",
              },
              content: "BIC / SWIFT",
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content: "__bankBicSwift__",
                },
              ],
            },
          ],
        } /*{
          type: 'text',
          styles: {
            ...styles.textContent,
            fontFamily: 'Helvetica-Bold',
          },
          content: '\nVEUILLEZ PARAPHER\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0PRESTATAIRE ___  \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 CLIENT ___',
         
            
          
        },
      
        {
          type: 'text',
          styles: {
            ...styles.textContent,
            fontSize: 7,
            fontStyle: "italic",
            
          },
          content: 'PLEASE INITIAL\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0SERVICE PROVIDER \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0   CLIENT',
          
        },*/,
        {
          type: "view",
          attrs: { wrap: false },
          styles: { paddingTop: 30 },
          items: [
            {
              type: "text",
              styles: {
                ...styles.textContent,
                fontSize: 9,
              },
              content:
                "Contrat Journée - Transport - Day Agreement V2.2 May 2021",
            },

            {
              type: "view",
              attrs: { wrap: false },
              styles: {
                border: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "CONDITIONS PARTICULIERES",
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                      },
                      content: "/ SPECIAL CONDITIONS",
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content: "__specialConditions__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                border: "1pt solid #000000",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                    padding: 5,
                  },
                  content: "AVENANT AU CONTRAT",
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                      },
                      content: "/ RIDER TO THE AGREEMENT",
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content:
                    "Tableau des modifications en cours de voyage, valant avenant au contrat sans impact sur le coût du transport",
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                      },
                      content:
                        "Table of changes during the cruise, representing a rider to the Agreement without impact on the contractually agreed price. State change and initial in boxes, Client and Captain.",
                    },
                  ],
                },

                {
                  type: "view",
                  repeated: true,
                  findRepeated: "addendumDay_change",
                  styles: {
                    flexDirection: "row",
                    padding: 5,
                    marginBottom: 5,
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        width: "70%",
                      },
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                          },
                          content: "__addendumDay_change__",
                        },
                      ],
                    },

                    {
                      type: "view",
                      styles: {
                        width: "15%",
                        height: "25pt",
                        border: "1pt solid #000000",
                      },
                      items: [],
                    },

                    {
                      type: "view",
                      styles: {
                        width: "15%",
                        height: "25pt",
                        border: "1pt solid #000000",
                      },
                      items: [],
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
            border: "1pt solid #000000",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "50%",
                borderRight: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "ASSURANCE COMPLEMENTAIRES",
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                      },
                      content: "Additional Insurance",
                    },
                  ],
                },

                {
                  type: "view",
                  specialType: "choice",
                  linkedField: "insuranceOrNo",
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "__insuranceOrNo__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        ...styles.checkboxWrapper,
                        width: "30%",
                      },
                      items: [
                        {
                          type: "view",
                          specialType: "checkbox",
                          linkedField: "additionalInsuranceLuggage",
                          styles: {
                            ...styles.checkbox,
                          },
                          items: [],
                        },

                        {
                          type: "text",
                          specialType: "display-everytime",
                          styles: {
                            ...styles.textContent,
                            fontSize: "8pt",
                            paddingTop: "3pt",
                          },
                          content: "bagages",
                          items: [
                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                                fontStyle: "italic",
                                fontSize: 8,
                              },
                              content: "/ __additionalInsuranceLuggage__",
                            },
                          ],
                        },
                      ],
                    },

                    {
                      type: "view",
                      styles: {
                        ...styles.checkboxWrapper,
                        width: "30%",
                      },
                      items: [
                        {
                          type: "view",
                          specialType: "checkbox",
                          linkedField: "additionalInsuranceAssistance",
                          styles: {
                            ...styles.checkbox,
                          },
                          items: [],
                        },

                        {
                          type: "text",
                          specialType: "display-everytime",
                          styles: {
                            ...styles.textContent,
                            fontSize: "8pt",
                            paddingTop: "3pt",
                          },
                          content: "assistance",
                          items: [
                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                                fontStyle: "italic",
                                fontSize: 8,
                              },
                              content: "/ __additionalInsuranceAssistance__",
                            },
                          ],
                        },
                      ],
                    },

                    {
                      type: "view",
                      styles: {
                        ...styles.checkboxWrapper,
                        width: "35%",
                      },
                      items: [
                        {
                          type: "view",
                          specialType: "checkbox",
                          linkedField: "additionalInsuranceCancellation",
                          styles: {
                            ...styles.checkbox,
                          },
                          items: [],
                        },

                        {
                          type: "text",
                          specialType: "display-everytime",
                          styles: {
                            ...styles.textContent,
                            fontSize: "8pt",
                            paddingTop: "3pt",
                          },
                          content: "annulation",
                          items: [
                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                                fontStyle: "italic",
                                fontSize: 8,
                              },
                              content: "/ __additionalInsuranceCancellation__",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    ...styles.checkboxWrapper,
                    marginTop: "5pt",
                    borderTop: "1pt solid #000000",
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        ...styles.checkbox,
                      },
                      items: [],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "Je déclare avoir lu, compris et accepter l'ensemble des termes du contrat, de ses conditions et de ses annexes",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontStyle: "italic",
                            fontSize: 8,
                          },
                          content:
                            "/ I hereby declare that I have read, understood and agree to all the terms of the Agreement, its conditions and appendices",
                        },
                      ],
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Client : __client__",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    marginTop: "10pt",
                  },
                  content:
                    'représenté par "nom, qualité du signataire, adresse complète", dûment habilité à représenter celui-ci pour la signature du présent document',
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                      },
                      content:
                        ' / Represented by "name, capacity of signatory, full address", duly authorised as the Client\'s representative for the signature of this Agreement',
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "SIGNATURE : ____",
                },

                {
                  type: "view",
                  styles: {
                    height: "50pt",
                  },
                  items: [],
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "50%",
                border: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "ATTENTION",
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "Le présent contrat est présenté en français et en anglais. En cas de litige entre les Parties sur l'interprétation des clauses de ce contrat, y compris ses annexes, seule la version en français sera considérée comme base juridique du Contrat.",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                      },
                      content:
                        "This Agreement is provided in French and in English. In case of a dispute regarding the interpretation of Agreement, only the French version, included its appendices, is regarded as the legal reference of Agreement.",
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    marginTop: "20pt",
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Prestataire : __serviceProvider__",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                    fontSize: 8,
                    marginTop: 0,
                  },
                  content: "Service provider",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    marginTop: "10pt",
                  },
                  content:
                    'représenté par "nom, qualité du signataire, adresse complète", dûment habilité à représenter celui-ci pour la signature du présent document',
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                      },
                      content:
                        'Represented by "name, capacity of signatory, full address", duly authorised as the Service Provider\'s representative for signature of this Agreement',
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "SIGNATURE : ____",
                },

                {
                  type: "view",
                  styles: {
                    height: "50pt",
                  },
                  items: [],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          attrs: { wrap: false },
          styles: {
            marginTop: "3pt",
            border: "1pt solid #000000",
            flexDirection: "row",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "33%",
                borderRight: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Courtier __brokerName__",
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "Broker",
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    paddingBottom: "50pt",
                  },
                  content:
                    'représenté par "nom, qualité du signataire, adresse complète", dûment habilité à représenter celui-ci pour la signature du présent document',
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                      },
                      content:
                        'Represented by "name, capacity of signatory, full address", duly authorised as the Broker\'s representative for signature of this Agreement',
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "33%",
                borderRight: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Agent central __centralAgent__",
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "Central agent",
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    paddingBottom: "50pt",
                  },
                  content:
                    'représenté par "nom, qualité du signataire, adresse complète", dûment habilité à représenter celui-ci pour la signature du présent document',
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: "8",
                      },
                      content:
                        'Represented by "name, capacity of signatory, full address", duly authorised as the Central agent\'s representative for signature of this Agreement',
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "33%",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Séquestre __stakeholder__",
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                        marginTop: 0,
                      },
                      content: "Stakeholder",
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    paddingBottom: "50pt",
                  },
                  content:
                    'représenté par "nom, qualité du signataire, adresse complète", dûment habilité à représenter celui-ci pour la signature du présent document',
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                        fontSize: 8,
                      },
                      content:
                        'Represented by "name, capacity of signatory, full address", duly authorised as the Stakeholder\'s representative for signature of this Agreement',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default jsonView;
