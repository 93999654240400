import React, { useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import classes from "../assets/css/vessels.module.css";
import { vesselServices } from "../services/vessels.services";
import { useSelector } from "react-redux";
import { Loader } from "../../_components";
import { history } from "../../_helpers";

export default function AddVessel() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.authentication.user);
  const [vessel, setVessel] = useState({
    sheetName: "",
    vesselName: "",
    vesselIMO: "",
    vesselRegistryPort: "",
    vesselFlag: "",
    vesselType: "",
    vesselLOA: "",
    vesselMaxPassengersWithStaterooms: "",
    vesselDayPassengersOnBoard: "",
    vesselServiceProvider: "",
    vesselServiceProviderAddress: "",
    vesselServiceProviderCompany: "",
    vesselServiceProviderInsuranceRef: "",
    vesselServiceProviderVAT: "",
    vesselCentralAgent: "",
    vesselCentralAgentAddress: "",
    vesselCentralAgentCompany: "",
    vesselCentralAgentInsuranceRef: "",
    vesselCentralAgentVAT: "",
    vesselCentralAgentMYBA: "",
    vesselStakeholder: "",
    vesselStakeholderAddress: "",
    vesselStakeholderCompany: "",
    vesselStakeholderInsuranceRef: "",
    vesselStakeholderMYBA: "",
    vesselStakeholderVAT: "",
    vesselStakeholderFinancialGuarantee: "",
    vesselSpecialConditions: "",
  });

  useMemo(() => {
    setVessel((prevVessel) => ({
      ...prevVessel,
      vesselCentralAgent: user?.company?.name,
      vesselCentralAgentAddress: user?.company?.address1,
      vesselCentralAgentCompany: user?.company?.ecpyId,
      vesselCentralAgentInsuranceRef: user?.company?.insurance,
      vesselCentralAgentVAT: user?.company?.VATnumber,
      vesselCentralAgentMYBA: user?.company?.MYBAid,
    }));
  }, [user]);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    setVessel({ ...vessel, [e.target.name]: e.target.value });
  };

  const handleChangeFloat = (e) => {
    const converted = parseFloat(e.target.value);
    setVessel({ ...vessel, [e.target.name]: converted });
  };

  const handleChangeNumber = (e) => {
    const converted = parseInt(e.target.value);
    setVessel({ ...vessel, [e.target.name]: converted });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await vesselServices.add(vessel, user?.id);
      history.push("/vessels");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleAsCentralAgent = () => {
    setVessel({
      ...vessel,
      vesselStakeholder: vessel?.vesselCentralAgent,
      vesselStakeholderAddress: vessel?.vesselCentralAgentAddress,
      vesselStakeholderCompany: vessel?.vesselCentralAgentCompany,
      vesselStakeholderInsuranceRef: vessel?.vesselCentralAgentInsuranceRef,
      vesselStakeholderVAT: vessel?.vesselCentralAgentVAT,
      vesselStakeholderMYBA: vessel?.vesselCentralAgentMYBA,
    });
  };
  const handleCancel = () => {
    history.push("/vessels");
  };

  const handleSubmitAndDefineUsers = async () => {
    try {
      setIsLoading(true);
      const payload = await vesselServices.add(vessel, user?.id);
      history.push(`/vessels/edit/${payload?.id}`);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loader isShown={isLoading} />

      <h1 className="page-title">{t("Add a new vessel")}</h1>

      <Form>
        <Form.Group>
          <Form.Label>
            {t("Name")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Sheet name")}
            required
            name="sheetName"
            maxLength={40}
            value={vessel?.sheetName}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Name of the vessel")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Name of the vessel")}
            required
            name="vesselName"
            maxLength={30}
            value={vessel?.vesselName}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("IMO number")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("IMO number")}
            required
            name="vesselIMO"
            maxLength={20}
            value={vessel?.vesselIMO}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Port of registry")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Port of registry")}
            required
            name="vesselRegistryPort"
            maxLength={30}
            value={vessel?.vesselRegistryPort}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Flag")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Flag")}
            required
            name="vesselFlag"
            maxLength={20}
            value={vessel.vesselFlag}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Type")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Group>
            <Form.Check
              type="radio"
              label="M/Y"
              value={"M/Y"}
              required
              name="vesselType"
              onChange={handleChange}
            />
            <Form.Check
              type="radio"
              label={"S/Y"}
              value={"S/Y"}
              required
              name="vesselType"
              onChange={handleChange}
            />{" "}
          </Form.Group>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Length overall")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="number"
            placeholder={t("xxx.xx")}
            required
            name="vesselLOA"
            value={vessel?.vesselLOA}
            onChange={handleChangeFloat}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Maximum number of Passengers with staterooms")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="number"
            placeholder={t("xx")}
            required
            name="vesselMaxPassengersWithStaterooms"
            value={vessel?.vesselMaxPassengersWithStaterooms}
            onChange={handleChangeNumber}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Day Passengers on board")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="number"
            placeholder={t("xx")}
            required
            name="vesselDayPassengersOnBoard"
            value={vessel.vesselDayPassengersOnBoard}
            onChange={handleChangeNumber}
          />
        </Form.Group>
        <hr />
        <h1 className="page-subtitle">{t("Service Provider")}</h1>
        <Form.Group>
          <Form.Label>
            {t("Service provider")} <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Service provider")}
            required
            name="vesselServiceProvider"
            maxLength={40}
            value={vessel?.vesselServiceProvider}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Service provider address")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Service provider address")}
            required
            name="vesselServiceProviderAddress"
            maxLength={150}
            value={vessel?.vesselServiceProviderAddress}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Service provider Company ID")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Service provider CompanyID")}
            required
            name="vesselServiceProviderCompany"
            maxLength={40}
            value={vessel?.vesselServiceProviderCompany}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Service provider insurance reference")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Service provider insurance reference")}
            required
            name="vesselServiceProviderInsuranceRef"
            maxLength={30}
            value={vessel?.vesselServiceProviderInsuranceRef}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {t("Service provider VAT number")}{" "}
            <span style={{ color: "#793b2f" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Service provider VAT number")}
            required
            name="vesselServiceProviderVAT"
            maxLength={20}
            value={vessel?.vesselServiceProviderVAT}
            onChange={handleChange}
          />
        </Form.Group>
        <hr />
        <h1 className="page-subtitle">{t("Central Agent")}</h1>
        <Form.Group>
          <Form.Label>{t("Central agent")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent")}
            name="vesselCentralAgent"
            maxLength={40}
            value={vessel?.vesselCentralAgent}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Central agent address")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent address")}
            name="vesselCentralAgentAddress"
            maxLength={75}
            value={vessel?.vesselCentralAgentAddress}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Central agent Company ID")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent Company ID")}
            name="vesselCentralAgentCompany"
            maxLength={40}
            value={vessel?.vesselCentralAgentCompany}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Central agent insurance reference")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent insurance reference")}
            name="vesselCentralAgentInsuranceRef"
            maxLength={30}
            value={vessel?.vesselCentralAgentInsuranceRef}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Central agent VAT number")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent VAT number")}
            name="vesselCentralAgentVAT"
            maxLength={20}
            value={vessel?.vesselCentralAgentVAT}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Central agent MYBA id")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Central agent MYBA id")}
            name="vesselCentralAgentMYBA"
            maxLength={10}
            value={vessel?.vesselCentralAgentMYBA}
            onChange={handleChange}
          />
        </Form.Group>
        <hr />
        <div className="d-flex align-items-center">
          <h1 className="page-subtitle">{t("Stakeholder")}</h1>{" "}
          <Button
            variant="outline-primary"
            style={{ marginLeft: "10px" }}
            onClick={handleAsCentralAgent}
          >
            {t("Same as Central Agent")}
          </Button>
        </div>
        <Form.Group>
          <Form.Label>{t("Stakeholder")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder")}
            name="vesselStakeholder"
            maxLength={40}
            value={vessel?.vesselStakeholder}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder address")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder address")}
            name="vesselStakeholderAddress"
            maxLength={75}
            value={vessel?.vesselStakeholderAddress}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder Company ID")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder Company ID")}
            name="vesselStakeholderCompany"
            maxLength={40}
            value={vessel?.vesselStakeholderCompany}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder insurance reference")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder insurance reference")}
            name="vesselStakeholderInsuranceRef"
            maxLength={30}
            value={vessel?.vesselStakeholderInsuranceRef}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder VAT number")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder VAT number")}
            name="vesselStakeholderVAT"
            maxLength={20}
            value={vessel?.vesselStakeholderVAT}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder MYBA id")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder MYBA id")}
            name="vesselStakeholderMYBA"
            maxLength={10}
            value={vessel?.vesselStakeholderMYBA}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Stakeholder financial guarantee")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Stakeholder financial guarantee")}
            name="vesselStakeholderFinancialGuarantee"
            maxLength={30}
            value={vessel?.vesselStakeholderFinancialGuarantee}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("Special conditions")}</Form.Label>
          <Form.Control
            as="textarea"
            type="text"
            name="vesselSpecialConditions"
            rows={50}
            value={vessel?.vesselSpecialConditions}
            onChange={handleChange}
          />
        </Form.Group>
        <div className={classes.formButtonGroup}>
          <Button variant="primary" onClick={handleSubmit}>
            {t("Save")}
          </Button>
          <Button variant="primary" onClick={handleSubmitAndDefineUsers}>
            {t("Save and define users")}
          </Button>
          <Button variant="primary" onClick={handleCancel}>
            {t("Cancel")}
          </Button>
        </div>
      </Form>
    </>
  );
}
