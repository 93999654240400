export const odooServices = {
  order,
};

async function order(payload) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_ODOO_API_ORDER}/order_token`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    return await res.json();
  } catch (error) {
    throw new Error("Error occurred while attempting to access Odoo");
  }
}
