import React, { useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { history } from "../../_helpers";
import { useSelector } from "react-redux";
import { vesselServices } from "../services/vessels.services";
import * as moment from "moment";
import RemoveVessel from "./RemoveVessel";
import CopyVessel from "./CopyVessel";
import TransferVessel from "./TransferVessel";
import AssociateUserVessel from "./AssociateUserVessel";

const initModal = {
  vesselId: null,
  show: false,
  sheetName: "",
};

export default function VesselsList() {
  const { t } = useTranslation();
  const user = useSelector((state) => state.authentication.user);
  const [vessels, setVessels] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(initModal);
  const [showCopyModal, setShowCopyModal] = useState(initModal);
  const [showTransferModal, setShowTransferModal] = useState(initModal);
  const [showAssociateModal, setShowAssociateModal] = useState(initModal);

  useMemo(async () => {
    const response = await vesselServices?.getVesselsByAuthor(user?.id);
    setVessels(response);
  }, [user]);

  const handleEdit = (id) => {
    history.push(`/vessels/edit/${id}`);
  };

  const toggleDeleteModal = (id, sheetName) => {
    const vesselId = !showDeleteModal.show === true ? id : null;
    const sheetNameValue = !showDeleteModal.show === true ? sheetName : null;
    setShowDeleteModal({
      vesselId,
      sheetName: sheetNameValue,
      show: !showDeleteModal.show,
    });
  };

  const toggleCopyModal = (id, sheetName) => {
    const vesselId = !showCopyModal.show === true ? id : null;
    const sheetNameValue = !showCopyModal.show === true ? sheetName : null;
    setShowCopyModal({
      vesselId,
      sheetName: sheetNameValue,
      show: !showCopyModal.show,
    });
  };

  const toggleTransferModal = (id, sheetName) => {
    const vesselId = !showTransferModal.show === true ? id : null;
    const sheetNameValue = !showTransferModal.show === true ? sheetName : null;
    setShowTransferModal({
      vesselId,
      sheetName: sheetNameValue,
      show: !showTransferModal.show,
    });
  };

  const toggleAssociateModal = (id, sheetName) => {
    const vesselId = !showAssociateModal.show === true ? id : null;
    const sheetNameValue = !showAssociateModal.show === true ? sheetName : null;
    setShowAssociateModal({
      vesselId,
      sheetName: sheetNameValue,
      show: !showAssociateModal.show,
    });
  };

  return (
    <>
      <RemoveVessel
        vesselId={showDeleteModal?.vesselId}
        toggle={toggleDeleteModal}
        show={showDeleteModal?.show}
        setVessels={setVessels}
        userId={user?.id}
        sheetName={showDeleteModal?.sheetName}
      />

      <CopyVessel
        vesselId={showCopyModal?.vesselId}
        toggle={toggleCopyModal}
        show={showCopyModal?.show}
        sheetName={showCopyModal?.sheetName}
        setVessels={setVessels}
        userId={user?.id}
      />

      <TransferVessel
        vesselId={showTransferModal?.vesselId}
        toggle={toggleTransferModal}
        show={showTransferModal?.show}
        sheetName={showTransferModal?.sheetName}
        setVessels={setVessels}
        userId={user?.id}
      />

      <AssociateUserVessel
        vesselId={showAssociateModal?.vesselId}
        toggle={toggleAssociateModal}
        show={showAssociateModal?.show}
        sheetName={showAssociateModal?.sheetName}
      />
      <h1 className="page-title">{t("My vessels")}</h1>
      <Button
        variant="success"
        size="sm"
        style={{
          marginLeft: "15px",
          padding: "2px 10px",
          borderRadius: "5px",
          fontWeight: "bold",
          fontSize: "16px",
        }}
        onClick={() => history.push("/vessels/new")}
      >
        + {t("New")}
      </Button>
      <Table bordered hover style={{ textAlign: "center" }}>
        <thead>
          <tr
            style={{
              textAlign: "center",
              backgroundColor: "#b3b2b2",
              color: "#FFFFFF",
            }}
          >
            <th>{t("Name")}</th>

            <th>{t("Vessel")}</th>

            <th>{t("Type")}</th>

            <th>{t("Stakeholder")}</th>

            <th>{t("CA")}</th>
            <th>{t("Last modified")}</th>

            <th>{t("Actions")}</th>
          </tr>
        </thead>
        {vessels?.map((vessel) => (
          <tr key={vessel?.id}>
            <td>{vessel?.sheetName}</td>
            <td>{vessel?.vesselName}</td>
            <td>{vessel?.vesselType}</td>
            <td>{vessel?.vesselStakeholder}</td>
            <td>{vessel?.vesselCentralAgent}</td>
            <td>{moment(vessel?.lastUpdate).format("DD/MM/YYYY - HH:mm")}</td>
            <td>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => handleEdit(vessel?.id)}
              >
                <i className="fa fa-edit"></i>
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => toggleDeleteModal(vessel?.id, vessel?.sheetName)}
              >
                <i className="fa fa-trash"></i>
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => toggleCopyModal(vessel?.id, vessel?.sheetName)}
              >
                <i className="fa fa-copy"></i>
              </Button>

              <Button
                variant="outline-primary"
                size="sm"
                onClick={() =>
                  toggleTransferModal(vessel?.id, vessel?.sheetName)
                }
              >
                <i className="fa fa-paper-plane"></i>{" "}
              </Button>

              <Button
                variant="outline-primary"
                size="sm"
                onClick={() =>
                  toggleAssociateModal(vessel?.id, vessel?.sheetName)
                }
              >
                <i className="fa fa-user-plus"></i>{" "}
              </Button>
            </td>
          </tr>
        ))}
      </Table>
    </>
  );
}
