import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { brokersService, contractFoldersService } from "../../_services";
import { useTranslation } from "react-i18next";
import { Button, Table } from "react-bootstrap";
import { decrypt } from "../../_helpers";
import Select from "react-select";
import classes from "./assets/agreements.module.css";

export default function AdminAgreements() {
  const [agreements, setAgreements] = useState([]);
  const [initAgreements, setInitAgreements] = useState([]);
  const user = useSelector((state) => state.authentication.user);
  const { t } = useTranslation();
  const statusFilters = [
    { value: "draft", label: "Draft" },
    { value: "contract", label: "Contract" },
  ];
  const [brokersFilter, setBrokersFilter] = useState([]);

  useMemo(async () => {
    const response = await contractFoldersService?.getCfAdmin(user?.id);
    setAgreements(response);
    setInitAgreements(response);
    const brokers = await brokersService?.findBrokers();
    setBrokersFilter(brokers);
  }, [user]);

  const handleSelectChangeStatusFilters = (e) => {
    if (e === null) {
      setAgreements(initAgreements);
    } else {
      const filtered = initAgreements?.filter(
        (agreement) => agreement?.status === e?.value
      );
      setAgreements(filtered);
    }
  };

  const handleSelectChangeBrokerFilters = (e) => {
    if (e === null) {
      setAgreements(initAgreements);
    } else {
      const filtered = initAgreements?.filter(
        (agreement) => agreement?.current_owner === e?.value
      );
      setAgreements(filtered);
    }
  };

  const handleSelectChangeTypeFilters = (e) => {
    if (e === null) {
      setAgreements(initAgreements);
    } else {
      const filtered = initAgreements?.filter(
        (agreement) => agreement?.status === e?.value
      );
      setAgreements(filtered);
    }
  };
  return (
    <>
      <h1 className="page-title">{t("Assigned agreements")}</h1>
      <div className={classes.filters}>
        <Select
          name="react-select-status-filters"
          options={statusFilters}
          isClearable={true}
          isSearchable={true}
          onChange={(e) => handleSelectChangeStatusFilters(e)}
          classNamePrefix="react-select"
          className={classes["filter-select"]}
          placeholder="Select status"
        />
        <Select
          name="react-select-status-brokers"
          options={brokersFilter}
          isClearable={true}
          isSearchable={true}
          onChange={(e) => handleSelectChangeBrokerFilters(e)}
          classNamePrefix="react-select"
          className={classes["filter-select"]}
          placeholder="Select broker"
        />
        <Select
          name="react-select-status-brokers"
          options={statusFilters}
          isClearable={true}
          isSearchable={true}
          onChange={(e) => handleSelectChangeTypeFilters(e)}
          classNamePrefix="react-select"
          className={classes["filter-select"]}
          placeholder="Select type"
        />
      </div>
      <Table bordered hover style={{ textAlign: "center" }}>
        <thead>
          <tr
            style={{
              textAlign: "center",
              backgroundColor: "#b3b2b2",
              color: "#FFFFFF",
            }}
          >
            <th>{t("Name")}</th>

            <th>{t("Status")}</th>

            <th>{t("Lang")}</th>

            <th>{t("Version")}</th>

            <th>{t("Current owner")}</th>

            <th>{t("Actions")}</th>
          </tr>
        </thead>
        {agreements?.map((agreement) => (
          <tr key={agreement?.id}>
            <td>
              {decrypt(
                agreement?.name,
                agreement?.new_owner_public_key,
                agreement?.new_owner_secret_key
              )}
            </td>
            <td>
              {agreement?.status === "draft" ? (
                <span style={{ color: "#e9725b" }}>{t("Draft")}</span>
              ) : (
                <span style={{ color: "#218838" }}>{t("Contract")}</span>
              )}
            </td>
            <td>{agreement?.lang}</td>
            <td>{agreement?.version}</td>
            <td>{agreement?.current_owner}</td>
            <td>
              <Button variant="outline-primary" size="sm">
                <i className="fa fa-user-cog"></i>
              </Button>
            </td>
          </tr>
        ))}
      </Table>
    </>
  );
}
