/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const HomePage = ({ auth }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="home-layout"></div>

      <div className="home-content">
        <div className="row d-flex flex-column">
          <div className="home-title col-md-6">
            <h1 className="page-title">
              {t("ECPY Transport and Charter Agreements Platform")}
            </h1>

            <p>{t("Please use your credentials to enter the platform.")}</p>
          </div>
        </div>
        <div className="home-buttons col-md-6">
          <a
            href="https://www.youtube.com/playlist?list=PLpdKN3dFXnTc3wwIuomkHbIpnJGcsTSKr"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary home-button padding-button"
            /*           style={{
              display: "block",
              width: "100%",
              backgroundColor: "#e9725b",
              padding: "15px 0",
              fontSize: "24px",
              marginTop: "25px",
            }} */
          >
            Tutorial
          </a>

          <a
            href={
              i18n.language === "en"
                ? "https://www.ecpy.org/en_GB/contactus"
                : "https://www.ecpy.org/contactus"
            }
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary home-button padding-button"
          >
            {t("Contact us")}
          </a>

          <Link to="/general-terms" className="btn btn-secondary home-button">
            {t("General terms and conditions")}
          </Link>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authentication,
});

const connectedHome = connect(mapStateToProps)(HomePage);
export { connectedHome as HomePage };
