import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import classes from "../assets/css/vessels.module.css";
import { vesselServices } from "../services/vessels.services";
import { useDispatch } from "react-redux";
import { alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";
import { Drawer } from "@mui/material";
export default function AssociatedUsersList({
  show,
  toggle,
  sheetName,
  vesselId,
}) {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const users = await vesselServices?.getUsersByVessel(vesselId);
      setUsers(users);
    }
    fetchData();
  }, [show, vesselId]);

  const handleUnlinkUser = async (userId) => {
    try {
      const unlink = await vesselServices?.unlinkUser(vesselId, userId);
      dispatch(alertActions.setAlert(t(unlink), alertConstants.SUCCESS));
      const users = await vesselServices?.getUsersByVessel(vesselId);
      setUsers(users);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Drawer open={show} onClose={toggle} anchor="right">
      <div className={classes?.drawer}>
        <span className={classes?.listGroupTitle}>
          <i className="fa fa-anchor"></i> {sheetName} -{" "}
          {t("List of associated users")}
        </span>

        <Table bordered hover style={{ textAlign: "center" }}>
          <thead>
            <tr
              style={{
                textAlign: "center",
                backgroundColor: "#e9725b",
                color: "#FFFFFF",
              }}
            >
              <th>{t("Email")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, index) => (
              <tr key={index}>
                <td>{user?.user_email}</td>
                <td>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleUnlinkUser(user?.user_id)}
                  >
                    <i className="fa fa-user-minus"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Drawer>
  );
}
