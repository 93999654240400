import styles from "./ContractStyles";

const jsonView = {
  type: "page",
  items: [
    {
      type: "view",
      styles: {
        margin: 10,
        marginLeft: 20,
        padding: 10,
      },
      items: [
        {
          type: "text",
          styles: {
            fontSize: 16,
            marginTop: -5,
            textAlign: "center",
            fontFamily: "Helvetica-Bold",
          },
          content: "AGENCY AGREEMENT FOR YACHTING SERVICES",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
            textAlign: "center",
            fontStyle: "italic",
          },
          content:
            "This document, prepared and provided by ECPY to its members, creates a transparent intermediary agreement in view of the signature of Transport and Services Agreements for Yachts.",
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
            fontFamily: "Helvetica-Bold",
            textAlign: "center",
          },
          content: "THE PRINCIPAL",
        },

        {
          type: "view",
          styles: {
            border: "1pt solid #000000",
          },
          items: [
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                    borderRight: "1pt solid #000000",
                    borderBottom: "1pt solid #000000",
                    padding: "5pt",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "1. PRINCIPAL",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                            fontStyle: "italic",
                          },
                          content:
                            ' represented by "name, signatory\'s status, full address", duly authorized to represent the Principal for the signature of this document',
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "__agreement_principal__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "50%",
                    borderBottom: "1pt solid #000000",
                    padding: "5pt",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "2. Status of the Principal",
                    },

                    {
                      type: "view",
                      styles: {
                        flexDirection: "row",
                      },
                      items: [
                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField: "agreement_principal_status_owner",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "__agreement_principal_status_owner__",
                            },
                          ],
                        },

                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField:
                                "agreement_principal_status_charterer",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content:
                                "__agreement_principal_status_charterer__",
                            },
                          ],
                        },

                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField: "agreement_principal_status_other",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "__agreement_principal_status_other__",
                            },
                          ],
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "3. Type of agreement",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "__agreement_type__",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "AGREEMENT CURRENCY : __agreement_currency__",
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                    borderRight: "1pt solid #000000",
                    padding: "5pt",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "4. Name and characteristics of yacht",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "NAME : __agreement_yacht_name__",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "IMO N° : __agreement_imo_number__",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "NAME : __agreement_flag_state__",
                    },

                    {
                      type: "view",
                      styles: {
                        flexDirection: "row",
                      },
                      items: [
                        {
                          type: "view",
                          styles: {
                            width: "50%",
                          },
                          items: [
                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "LENGTH OA : __agreement_lenght_OA__",
                            },
                          ],
                        },

                        {
                          type: "view",
                          styles: {
                            width: "50%",
                          },
                          items: [
                            {
                              type: "text",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "TYPE : __agreement_yacht_type__",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "50%",
                    padding: "5pt",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "5. Agreement term",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "From __agreement_term_from__ to __agreement_term_to__",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "EXCLUSIVE AGREEMENT : __agreement_term_exclusive__",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "6. Cruising area",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "__agreement_cruising_area__",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
            fontFamily: "Helvetica-Bold",
            textAlign: "center",
          },
          content: "THE AGENT",
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
            border: "1pt solid #000000",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "50%",
                borderRight: "1pt solid #000000",
                padding: "5pt",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "7. AGENT",
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                      },
                      content:
                        'represented by "name, signatory\'s status, full address", duly authorized to represent the client for the signature of this document',
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content: "__agreement_agent__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "50%",
                padding: "5pt",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "8. Role of the agent (cf. details below)",
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        ...styles.checkboxWrapper,
                      },
                      items: [
                        {
                          type: "view",
                          specialType: "checkbox",
                          linkedField: "agreement_agent_role_broker",
                          styles: {
                            ...styles.checkbox,
                          },
                          items: [],
                        },

                        {
                          type: "text",
                          specialType: "display-everytime",
                          styles: {
                            ...styles.textContent,
                          },
                          content: "__agreement_agent_role_broker__",
                        },
                      ],
                    },

                    {
                      type: "view",
                      styles: {
                        ...styles.checkboxWrapper,
                      },
                      items: [
                        {
                          type: "view",
                          specialType: "checkbox",
                          linkedField: "agreement_agent_role_ca",
                          styles: {
                            ...styles.checkbox,
                          },
                          items: [],
                        },

                        {
                          type: "text",
                          specialType: "display-everytime",
                          styles: {
                            ...styles.textContent,
                          },
                          content: "__agreement_agent_role_ca__",
                        },
                      ],
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        ...styles.checkboxWrapper,
                      },
                      items: [
                        {
                          type: "view",
                          specialType: "checkbox",
                          linkedField: "agreement_agent_role_stakeholder",
                          styles: {
                            ...styles.checkbox,
                          },
                          items: [],
                        },

                        {
                          type: "text",
                          specialType: "display-everytime",
                          styles: {
                            ...styles.textContent,
                          },
                          content: "__agreement_agent_role_stakeholder__",
                        },
                      ],
                    },

                    {
                      type: "view",
                      styles: {
                        ...styles.checkboxWrapper,
                      },
                      items: [
                        {
                          type: "view",
                          specialType: "checkbox",
                          linkedField: "agreement_agent_role_other",
                          styles: {
                            ...styles.checkbox,
                          },
                          items: [],
                        },

                        {
                          type: "text",
                          specialType: "display-everytime",
                          styles: {
                            ...styles.textContent,
                          },
                          content: "__agreement_agent_role_other__",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "text",
          styles: {
            ...styles.textContent,
            fontFamily: "Helvetica-Bold",
            textAlign: "center",
          },
          content: "ADDITIONAL DETAILS REGARDING THE AGREEMENT",
        },

        {
          type: "view",
          styles: {
            border: "1pt solid #000000",
          },

          items: [
            {
              type: "view",
              styles: {
                borderBottom: "1pt solid #000000",
                padding: "5pt",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "9. Delegated operations",
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        width: "50%",
                      },
                      items: [
                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField: "agreement_delegated_operations_1",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "__agreement_delegated_operations_1__",
                            },
                          ],
                        },

                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField: "agreement_delegated_operations_2",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "__agreement_delegated_operations_2__",
                            },
                          ],
                        },

                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField: "agreement_delegated_operations_3",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "__agreement_delegated_operations_3__",
                            },
                          ],
                        },

                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField: "agreement_delegated_operations_4",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "__agreement_delegated_operations_4__",
                            },
                          ],
                        },

                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField: "agreement_delegated_operations_5",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "__agreement_delegated_operations_5__",
                            },
                          ],
                        },
                      ],
                    },

                    {
                      type: "view",
                      styles: {
                        width: "50%",
                      },
                      items: [
                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField: "agreement_delegated_operations_6",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "__agreement_delegated_operations_6__",
                            },
                          ],
                        },

                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField: "agreement_delegated_operations_7",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "__agreement_delegated_operations_7__",
                            },
                          ],
                        },

                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField: "agreement_delegated_operations_8",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "__agreement_delegated_operations_8__",
                            },
                          ],
                        },

                        {
                          type: "view",
                          styles: {
                            ...styles.checkboxWrapper,
                          },
                          items: [
                            {
                              type: "view",
                              specialType: "checkbox",
                              linkedField: "agreement_delegated_operations_9",
                              styles: {
                                ...styles.checkbox,
                              },
                              items: [],
                            },

                            {
                              type: "text",
                              specialType: "display-everytime",
                              styles: {
                                ...styles.textContent,
                              },
                              content: "__agreement_delegated_operations_9__",
                            },
                          ],
                        },

                        {
                          type: "view",
                          repeated: true,
                          findRepeated: "agreement_other_delegated",
                          items: [
                            {
                              type: "view",
                              styles: {
                                ...styles.checkboxWrapper,
                              },
                              items: [
                                {
                                  type: "view",
                                  specialType: "checkbox",
                                  linkedField: "agreement_other_delegated",
                                  styles: {
                                    ...styles.checkbox,
                                    ...styles.checkboxChecked,
                                  },
                                  items: [],
                                },

                                {
                                  type: "text",
                                  specialType: "display-everytime",
                                  styles: {
                                    ...styles.textContent,
                                  },
                                  content: "__agreement_other_delegated__",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                    borderRight: "1pt solid #000000",
                    padding: "5pt",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "10. Agent's bank details (if Stakeholder)",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "BANK : __agreement_agent_bank_details_bankDesignation__",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "ACCOUNT NAME : __agreement_agent_bank_details_bankAccountName__",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "ADDRESS : __agreement_agent_bank_details_bankAddress1__ __agreement_agent_bank_details_bankAddress2__",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "IBAN : __agreement_agent_bank_details_bankIban__",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "SWIFT : __agreement_agent_bank_details_bankBicSwift__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "50%",
                    height: "100%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "11. Agent's insurance and financial guarantee",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                          },
                          content: "(when applicable)",
                        },
                      ],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "INSURANCE : __agreement_agent_insurance__",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "FINANCIAL GUARANTEE : __agreement_agent_financial__",
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    width: "50%",
                    borderRight: "1pt solid #000000",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "12. Agent's remuneration",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "COMMISSION : __agreement_agent_commission__",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontStyle: "italic",
                      },
                      content: "(specify currency)",
                      items: [
                        {
                          type: "text",
                          styles: {
                            ...styles.textContent,
                          },
                          content: "__agreement_agent_currency__",
                        },
                      ],
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    width: "50%",
                    height: "100%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                        fontFamily: "Helvetica-Bold",
                      },
                      content: "13. Agent's payment terms and conditions",
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content: "__agreement_agent_terms__",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          styles: {
            border: "1pt solid #000000",
            flexDirection: "row",
          },
          items: [
            {
              type: "view",
              styles: {
                borderRight: "1pt solid #000000",
                width: "50%",
                padding: "5pt",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    ...styles.checkboxWrapper,
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        ...styles.checkbox,
                      },
                      items: [],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "I have read, understood and accept all of the terms of the agreement and the general conditions overleaf.",
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content: "Date, signature and stamp of the Principal",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                  },
                  content: '"Valid for agreement"',
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "FOR THE PRINCIPAL",
                },

                {
                  type: "view",
                  styles: {
                    height: "50pt",
                  },
                  items: [],
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "50%",
                padding: "5pt",
              },
              items: [
                {
                  type: "view",
                  styles: {
                    ...styles.checkboxWrapper,
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        ...styles.checkbox,
                      },
                      items: [],
                    },

                    {
                      type: "text",
                      styles: {
                        ...styles.textContent,
                      },
                      content:
                        "I have read, understood and accept all of the terms of the agreement and the general conditions overleaf.",
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                  },
                  content: "Date, signature and stamp of the Agent",
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontStyle: "italic",
                  },
                  content: '"Valid for agreement"',
                },

                {
                  type: "text",
                  styles: {
                    ...styles.textContent,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "FOR THE AGENT",
                },

                {
                  type: "view",
                  styles: {
                    height: "50pt",
                  },
                  items: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default jsonView;
